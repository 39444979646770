import { PhysicalLocation, validatePhysicalLocation } from '@dsmaccy/echomodel'
import { Cascader } from 'antd'
import { useEffect, useState } from 'react'
import { ICity, ICountry, IState } from 'country-state-city'
import { MonitorSingleton } from '../utils/monitor'

interface Option {
    value: string
    label: any
    children?: Option[]
    disabled?: boolean
}

const validCountries = ['United States', 'Canada', 'United Kingdom', 'Japan']

export default function LocationSelect(props: {
    locationValue?: PhysicalLocation | null
    onChange: (industry: PhysicalLocation | null) => void
}) {
    const [cascaderOptions, setCascaderOptions] = useState<Option[]>([])

    const loadCountryStateCity = async () => {
        const { City, Country, State } = await import('country-state-city')

        setCascaderOptions(
            Country.getAllCountries()
                .filter(
                    (country: ICountry) =>
                        validCountries.includes(country.name) &&
                        State.getStatesOfCountry(country.isoCode).length > 0
                )
                .map((country: ICountry) => {
                    const stateChildren = State.getStatesOfCountry(
                        country.isoCode
                    )
                        .filter(
                            (state: IState) =>
                                City.getCitiesOfState(
                                    country.isoCode,
                                    state.isoCode
                                ).length > 0
                        )
                        .map((state: IState) => {
                            const cityChildren = City.getCitiesOfState(
                                country.isoCode,
                                state.isoCode
                            ).map((city: ICity) => {
                                return {
                                    value: city.name,
                                    label: city.name,
                                }
                            })

                            return {
                                value: state.name,
                                label: state.name,
                                children: cityChildren,
                            }
                        })

                    return {
                        value: country.name,
                        label: country.name,
                        children: stateChildren,
                    }
                })
        )
    }

    useEffect(() => {
        loadCountryStateCity()
    }, [])

    const onChange = (
        value: any[] | undefined,
        _selectOptions: (any | Option)[]
    ): void => {
        if (!value || value.length < 3) {
            props.onChange(null)
            return
        }

        let validatedPhysicalLocation: PhysicalLocation | null = null
        try {
            validatedPhysicalLocation = validatePhysicalLocation({
                country: value[0],
                state: value[1],
                city: value[2],
            })
        } catch (err) {
            MonitorSingleton.sendException(err)
            return
        }

        props.onChange(validatedPhysicalLocation)
    }

    let value: string[] = []
    try {
        const location = validatePhysicalLocation(props.locationValue)
        value = [location.country, location.state, location.city]
    } catch (err) {
        MonitorSingleton.sendException(err)
    }

    return (
        <Cascader
            showSearch
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            options={cascaderOptions}
            placeholder="Select Country/State/City"
        />
    )
}
