export default class RouteConstants {
    public static readonly HOME = '/'
    public static readonly LOGIN = '/login'

    public static readonly LIST_CONNECTIONS = '/connections'
    public static readonly VIEW_CONNECTION = '/connection/:entityId'
    public static readonly ADD_CONNECTION = '/add-connection'

    public static readonly LIST_ORGANIZATIONS = '/organizations'
    public static readonly VIEW_ORGANIZATION = '/organization/:entityId'
    public static readonly ADD_ORGANIZATION = '/add-organization'

    public static readonly LIST_DEALS = '/deals'
    public static readonly VIEW_DEAL = '/deal/:entityId'
    public static readonly ADD_DEAL = '/add-deal'

    public static readonly VIEW_EVENT = '/event/:entityId'
    public static readonly ADD_EVENT =
        '/organization/:organizationId/add-event/'

    public static readonly USER_SETTINGS = '/settings/user'
    public static readonly CHANGE_PASSWORD_SETTINGS =
        '/settings/change-password'
    public static readonly FORGOT_PASSWORD = '/forgot-password'

    public static viewExistingConnectionRoute(id: string): string {
        return this.VIEW_CONNECTION.replace(':entityId', id)
    }
    public static viewDealRoute(id: string): string {
        return this.VIEW_DEAL.replace(':entityId', id)
    }
    public static viewOrganizationRoute(id: string): string {
        return this.VIEW_ORGANIZATION.replace(':entityId', id)
    }

    public static viewEventRoute(entityId: string) {
        return this.VIEW_EVENT.replace(':entityId', entityId)
    }
    public static addEventRoute(organizationId: string) {
        return this.ADD_EVENT.replace(':organizationId', organizationId)
    }
}
