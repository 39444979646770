import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import { Industry } from '@dsmaccy/echomodel'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'
import { addSpacesForCamelCases } from '../../utils/data_mapping_utils'
import IndustrySelect from '../industry_select'

export default function TextOrInputIndustryFieldEdit(props: {
    industryValue?: Industry | null | undefined
    onCommitChange?: (newValue: Industry | null) => void
}) {
    const [industryTempValue, setIndustryTempValue] = useState<
        Industry | null | undefined
    >(props.industryValue)
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setIndustryTempValue(props?.industryValue)
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setIndustryTempValue(props?.industryValue)
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    let onCommitChange = () => {
        setEditField(false)
        if (props.onCommitChange) {
            if (!industryTempValue) {
                props.onCommitChange(null)
            } else {
                props.onCommitChange(industryTempValue)
            }
        }
    }
    if (editField) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                <IndustrySelect
                    industryValue={industryTempValue}
                    onChange={setIndustryTempValue}
                />
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    let displayValue = ''
    if (props.industryValue) {
        const majorType = addSpacesForCamelCases(props.industryValue?.type)
        const minorType = addSpacesForCamelCases(props.industryValue?.subtype)
        displayValue = `${majorType} / ${minorType}`
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {displayValue}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
