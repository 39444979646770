import {
    ExistingConnection,
    Event,
    Organization,
    Deal,
} from '@dsmaccy/echomodel'
import { useCallback, useEffect, useState } from 'react'
import { useGetUserInfo } from '../../../controllers/authentication/clients/auth0'
import { useDebounce } from '../../../hooks/useDebounce'
import useWindowDimensions, {
    WindowDimensions,
} from '../../../hooks/useWindowDimensions'
import { useNavigate } from 'react-router'
import ReactFlow, {
    Controls,
    Edge,
    Node,
    Position,
    applyNodeChanges,
} from 'reactflow'

import 'reactflow/dist/style.css'
import {
    loadDeals,
    loadEvents,
    loadExistingConnections,
    loadOrganizations,
} from '../../../utils/load_entities'

const MAX_WIDTH = 800
const MAX_HEIGHT = 300
const BASE_DIMENSION_WIDTH = 1280
const BASE_DIMENSION_HEIGHT = 559
const GRAPH_UPDATE_DEBOUNCE_DELAY_IN_MS = 1500

function calculateCanvasDimensions(
    dimensions: WindowDimensions
): WindowDimensions {
    // NOTE: Currently this method will constrain the width and height to a preset max and min
    //       We also use the max width and height as the default if canvas would be too small
    //       This implementation is dependent on the header and sider sizes and the use of antd

    let width = Math.min(
        MAX_WIDTH,
        dimensions.width - (BASE_DIMENSION_WIDTH - MAX_WIDTH)
    )

    if (width < 0) {
        width = MAX_WIDTH
    }

    let height = Math.min(
        MAX_HEIGHT,
        dimensions.height - (BASE_DIMENSION_HEIGHT - MAX_HEIGHT)
    )

    if (height < 0) {
        height = MAX_HEIGHT
    }

    return {
        width,
        height,
    }
}

export default function ReactFlowConnectionGraph() {
    const windowDimensions = useWindowDimensions()
    const debouncedWindowDimensions = useDebounce(
        windowDimensions,
        GRAPH_UPDATE_DEBOUNCE_DELAY_IN_MS
    )

    const [nodes, setNodes] = useState<Node<any, string | undefined>[]>([])
    const [edges, setEdges] = useState<Edge<any>[]>([])

    const [allConnections, setAllExistingConnections] = useState<
        ExistingConnection[] | undefined
    >()
    const [allEvents, setAllEvents] = useState<Event[] | undefined>()
    const [allOrganizations, setAllOrganizations] = useState<
        Organization[] | undefined
    >()
    const [allDeals, setAllDeals] = useState<Deal[] | undefined>()

    // Used for window sizing
    const startingDimensions = calculateCanvasDimensions(
        debouncedWindowDimensions
    )
    const [viewWidth, setViewWidth] = useState(startingDimensions.width)
    const [viewHeight, setViewHeight] = useState(startingDimensions.height)

    useEffect(() => {
        const newDimensions = calculateCanvasDimensions(
            debouncedWindowDimensions
        )
        setViewWidth(newDimensions.width)
        setViewHeight(newDimensions.height)
    }, [debouncedWindowDimensions])

    const navigate = useNavigate
    const getUserInfo = useGetUserInfo()

    useEffect(() => {
        loadExistingConnections(getUserInfo, navigate).then(
            (existingConnections: ExistingConnection[] | undefined) => {
                setAllExistingConnections(existingConnections)
            }
        )
        loadEvents(getUserInfo, navigate).then(
            (events: Event[] | undefined) => {
                setAllEvents(events)
            }
        )
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
        loadDeals(getUserInfo, navigate).then((deals: Deal[] | undefined) => {
            setAllDeals(deals)
        })
    }, [])

    useEffect(() => {
        const connectionNodes: Node<any, string | undefined>[] =
            allConnections?.map((connection, index) => {
                return {
                    id: connection.entityId,
                    type: 'default',
                    targetPosition: Position.Top,
                    position: { x: 0, y: 100 * index },
                    data: {
                        label: `${connection.firstName} ${connection.lastName}`,
                    },
                    style: { borderBlockColor: 'black' },
                }
            }) ?? []

        const organizationNodes: Node<any, string | undefined>[] =
            allOrganizations?.map((organization, index) => {
                return {
                    id: organization.entityId,
                    type: 'default',
                    targetPosition: Position.Top,
                    position: { x: 200, y: 100 * index },
                    data: {
                        label: organization.name,
                    },
                    style: { borderBlockColor: 'blue' },
                }
            }) ?? []

        const eventNodes: Node<any, string | undefined>[] =
            allEvents?.map((event, index) => {
                return {
                    id: event.entityId,
                    type: 'default',
                    targetPosition: Position.Top,
                    position: { x: 400, y: 100 * index },
                    data: {
                        label: event.name,
                    },
                    style: { borderBlockColor: 'green' },
                }
            }) ?? []

        const dealNodes: Node<any, string | undefined>[] =
            allDeals?.map((deal, index) => {
                return {
                    id: deal.entityId,
                    type: 'default',
                    targetPosition: Position.Top,
                    position: { x: 600, y: 100 * index },
                    data: {
                        label: deal.name,
                    },
                    style: { borderBlockColor: 'red' },
                }
            }) ?? []

        setNodes([
            ...connectionNodes,
            ...organizationNodes,
            ...eventNodes,
            ...dealNodes,
        ])

        const connectionEdges: Edge<any>[] =
            allConnections
                ?.filter((connection) => !!connection.introductionOrigin)
                .map((connection) => {
                    return {
                        id: `${connection.introductionOrigin?.id}_${connection.entityId}`,
                        source: connection.introductionOrigin?.id ?? '',
                        target: connection.entityId,
                    }
                }) ?? []

        const dealEdges: Edge<any>[] =
            allDeals
                ?.filter((deal) => !!deal.introductionOrigin)
                .map((deal) => {
                    return {
                        id: `${deal.introductionOrigin?.id}_${deal.entityId}`,
                        source: deal.introductionOrigin?.id ?? '',
                        target: deal.entityId,
                    }
                }) ?? []

        setEdges([...connectionEdges, ...dealEdges])
    }, [allConnections, allEvents, allOrganizations, allDeals])

    const onNodesChange = useCallback(
        (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
        []
    )
    return (
        <div
            style={{
                width: viewWidth,
                height: viewHeight,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
            >
                <Controls />
            </ReactFlow>
        </div>
    )
}
