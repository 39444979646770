import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import { PhysicalLocation } from '@dsmaccy/echomodel'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'
import LocationSelect from '../location_select'

export default function TextOrInputLocationFieldEdit(props: {
    locationValue?: PhysicalLocation | null | undefined
    onCommitChange?: (newValue: PhysicalLocation | null) => void
}) {
    const [locationTempValue, setLocationTempValue] = useState<
        PhysicalLocation | null | undefined
    >(props.locationValue)
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setLocationTempValue(props?.locationValue)
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setLocationTempValue(props?.locationValue)
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    let onCommitChange = () => {
        setEditField(false)
        if (props.onCommitChange) {
            if (!locationTempValue) {
                props.onCommitChange(null)
            } else {
                props.onCommitChange(locationTempValue)
            }
        }
    }

    if (editField) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                <LocationSelect
                    locationValue={locationTempValue}
                    onChange={setLocationTempValue}
                />
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    let displayValue = ''
    if (props.locationValue) {
        const countryName = props.locationValue?.country
        const stateName = props.locationValue?.state
        const cityName = props.locationValue?.city
        displayValue = `${countryName} / ${stateName} / ${cityName}`
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {displayValue}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
