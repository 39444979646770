import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'
import { addSpacesForCamelCases } from '../../utils/data_mapping_utils'
import { Select } from 'antd'

type OptionType = string | null

export default function TextOrSelectFieldEdit(props: {
    optionValue?: OptionType
    options: OptionType[]
    onCommitChange?: (newValue: OptionType) => void
}) {
    const [optionTempValue, setOptionTempValue] = useState<
        OptionType | null | undefined
    >(props.optionValue)
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setOptionTempValue(props?.optionValue)
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setOptionTempValue(props?.optionValue)
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    let onCommitChange = () => {
        setEditField(false)
        if (props.onCommitChange) {
            if (!optionTempValue) {
                props.onCommitChange(null)
            } else {
                props.onCommitChange(optionTempValue)
            }
        }
    }

    let display = (option: OptionType | undefined) => {
        return option ? addSpacesForCamelCases(option) : ''
    }

    if (editField) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                <Select
                    value={optionTempValue}
                    onChange={setOptionTempValue}
                    style={{ width: '100%' }}
                >
                    {props.options.map((option) => (
                        <Select.Option key={option} value={option}>
                            {display(option)}
                        </Select.Option>
                    ))}
                </Select>
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
                width: '100%',
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {display(props.optionValue)}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
