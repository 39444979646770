import {
    ExistingConnection,
    Organization,
    Event,
    IntroductionType,
    IntroductionOrigin,
} from '@dsmaccy/echomodel'
import { Cascader } from 'antd'

interface Option {
    value: string
    label: any
    children?: Option[]
    disabled?: boolean
}

export default function IntroductionSelect(props: {
    introductionOriginValue: IntroductionOrigin | null
    entityIdToOmit?: string | undefined
    existingConnections?: ExistingConnection[]
    organizations?: Organization[]
    events?: Event[]
    onChange: (newIntroductionOrigin: IntroductionOrigin | null) => void
}) {
    const cascaderOptions = [
        {
            value: IntroductionType.Person,
            label: 'Existing Connection',
            children: props?.existingConnections
                ?.filter(
                    (connection) => connection.entityId !== props.entityIdToOmit
                )
                ?.map((connection) => {
                    return {
                        value: connection.entityId,
                        label: connection.firstName + ' ' + connection.lastName,
                    }
                }),
            disabled: !props?.existingConnections?.length,
        },
        {
            value: IntroductionType.Event,
            label: 'Event',
            children: props?.events
                ?.filter((event) => event.entityId !== props.entityIdToOmit)
                ?.map((event) => {
                    return {
                        value: event.entityId,
                        label: event.name,
                    }
                }),
            disabled: !props?.events?.length,
        },
        {
            value: IntroductionType.Organization,
            label: 'Organization',
            children: props?.organizations
                ?.filter(
                    (organization) =>
                        organization.entityId !== props.entityIdToOmit
                )
                ?.map((organization) => {
                    return {
                        value: organization.entityId,
                        label: organization.name,
                    }
                }),
            disabled: !props?.organizations?.length,
        },
    ]

    return (
        <Cascader
            showSearch
            style={{ width: '100%' }}
            value={
                props?.introductionOriginValue
                    ? [
                          props?.introductionOriginValue?.type,
                          props?.introductionOriginValue?.id,
                      ]
                    : undefined
            }
            onChange={(
                values: any[] | undefined,
                _selectOptions: (any | Option)[]
            ) => {
                if (!values || values.length < 2) {
                    props?.onChange(null)
                    return
                }

                props?.onChange({
                    type: values[0],
                    id: values[1],
                })
            }}
            options={cascaderOptions}
            placeholder="Select Introducer"
        />
    )
}
