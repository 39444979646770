import Layout, { Content, Header } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import { useState } from 'react'
import MenuBar from '../organisms/menu_bar'
import NotAuthenticatedWrapper from '../organisms/not_authenticated_wrapper'
import SiteHeader from '../organisms/site_header'

export default function LoggedOutTemplate(props: any) {
    const [collapsed, setCollapsed] = useState(false)
    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <Layout>
                <Header style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                    <Content>
                        <SiteHeader
                            loggedIn={false}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    </Content>
                </Header>
                <Layout>
                    <Sider
                        collapsed={collapsed}
                        breakpoint="sm"
                        style={{
                            overflowY: 'auto',
                        }}
                    >
                        <MenuBar
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    </Sider>
                    <Content style={{ padding: 20, overflow: 'auto' }}>
                        <NotAuthenticatedWrapper>
                            {props?.children}
                        </NotAuthenticatedWrapper>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}
