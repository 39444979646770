import * as Amplify from 'aws-amplify'
import Entity from './Entity'


function getExpirationTime() {
    return Date.now() + 1000 * 60 * 2 // This is 2 minutes in millis
}

export default class EntityCache {
    public static getAllEntities<Entity>(
        cacheConstant: string
    ): Entity[] | undefined {
        const entities: Entity[] | undefined =
            Amplify.Cache.getItem(cacheConstant)
        if (entities) {
            return entities
        }
    }

    public static getSingleEntity(
        userId: string,
        entityId: string,
        cacheConstant: string
    ): Entity | undefined {
        const entities: Entity[] | undefined =
            Amplify.Cache.getItem(cacheConstant)

        // suboptimal: cache is just a list...

        if (entities) {
            const entity = entities.find(
                (entity) =>
                    entity.userId === userId && entity.entityId === entityId
            )
            if (entity) {
                return entity
            }
        }
        return undefined
    }

    public static setAllEntities<Entity>(
        entities: Entity[],
        cacheConstant: string
    ) {
        Amplify.Cache.setItem(cacheConstant, entities, {
            expires: getExpirationTime(),
        })
    }

    public static setSingleEntity<Entity>(
        entity: Entity,
        cacheConstant: string
    ) {
        // Do nothing here for now because the cache is just a list
    }

    public static addEntity<CreateEntity>(
        userId: string,
        entityId: string,
        createEntity: CreateEntity,
        cacheConstant: string,
        validateEntity: (entity: Entity) => any
    ) {
        const entities: Entity[] | undefined =
            Amplify.Cache.getItem(cacheConstant)
        if (!entities) {
            return
        }
        let entity = {
            userId,
            entityId: entityId,
            ...createEntity,
        }

        entity = validateEntity(entity)

        entities.push(entity)

        Amplify.Cache.setItem(cacheConstant, entities, {
            expires: getExpirationTime(),
        })
    }

    public static editEntity(newEntity: Entity, cacheConstant: string) {
        const entities: Entity[] | undefined =
            Amplify.Cache.getItem(cacheConstant)

        if (!entities) {
            return
        }

        const updatedPeople = entities.map((entity) => {
            if (
                newEntity.userId === entity.userId &&
                newEntity.entityId === entity.entityId
            ) {
                return newEntity
            }
            return entity
        })
        Amplify.Cache.setItem(cacheConstant, updatedPeople, {
            expires: getExpirationTime(),
        })
    }

    public static deleteEntity(
        userId: string,
        entityId: string,
        cacheConstant: string
    ) {
        const entities: Entity[] | undefined =
            Amplify.Cache.getItem(cacheConstant)
        if (!entities) {
            return
        }

        const updatedEntities = entities.filter(
            (entity) =>
                !(entity.userId === userId && entity.entityId === entityId)
        )
        Amplify.Cache.setItem(cacheConstant, updatedEntities, {
            expires: getExpirationTime(),
        })
    }
}
