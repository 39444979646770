import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Home from './pages/home'
import LoggedInTemplate from './templates/logged_in_template'
import LoggedOutTemplate from './templates/logged_out_template'
import Settings from './templates/settings'
import RouteConstants from './routes_constants'

import AddConnection from './pages/connections/add_connection'
import ViewExistingConnection from './pages/connections/view_existing_connection'
import ViewAllConnections from './pages/connections/view_all_connections'
import Auth0LoginPage from './pages/auth0_login'
import UserSettings from './pages/settings/user_settings'
import ForgotPassword from './pages/forgot_password'
import { PageNotFoundPage } from './pages/not_found'
import ViewAllDeals from './pages/deals/view_all_deals'
import ViewDeal from './pages/deals/view_deal'
import AddDeal from './pages/deals/add_deal'
import ViewAllOrganizations from './pages/organizations/view_all_organizations'
import AddOrganization from './pages/organizations/add_organization'
import ViewOrganization from './pages/organizations/view_organization'
import ViewEvent from './pages/organizations/events/view_event'
import AddEvent from './pages/organizations/events/add_event'

// TODO: Add code splitting by lazy loading:
// https://reactjs.org/docs/code-splitting.html#reactlazy
// const AddConnection = React.lazy(
//     () => import('./pages/connections/add_connection')
// )

// const ViewExistingConnection = React.lazy(
//     () => import('./pages/connections/view_existing_connection')
// )
// const ViewAllConnections = React.lazy(
//     () => import('./pages/connections/view_all_connections')
// )
// const Login = React.lazy(() => import('./pages/login'))
// const UserSettings = React.lazy(() => import('./pages/settings/user_settings'))
// const ChangePassword = React.lazy(
//     () => import('./pages/settings/change_password')
// )
// const ForgotPassword = React.lazy(() => import('./pages/forgot_password'))

// Pages are rendered dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function App() {
    return (
        <Router>
            <Routes>
                <Route
                    path="*"
                    element={
                        <LoggedInTemplate>
                            <PageNotFoundPage />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    index
                    element={
                        <LoggedInTemplate>
                            <Home />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.LOGIN}
                    element={
                        <LoggedOutTemplate>
                            <Auth0LoginPage />
                        </LoggedOutTemplate>
                    }
                />
                <Route
                    path={RouteConstants.FORGOT_PASSWORD}
                    element={
                        <LoggedOutTemplate>
                            <ForgotPassword />
                        </LoggedOutTemplate>
                    }
                />
                {/* Pages that require authentication */}
                {/* <SessionProvider session={session}> */}
                <Route
                    path={RouteConstants.LIST_CONNECTIONS}
                    element={
                        <LoggedInTemplate>
                            <ViewAllConnections />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.VIEW_CONNECTION}
                    element={
                        <LoggedInTemplate>
                            <ViewExistingConnection />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.ADD_CONNECTION}
                    element={
                        <LoggedInTemplate>
                            <AddConnection />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.LIST_DEALS}
                    element={
                        <LoggedInTemplate>
                            <ViewAllDeals />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.VIEW_DEAL}
                    element={
                        <LoggedInTemplate>
                            <ViewDeal />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.ADD_DEAL}
                    element={
                        <LoggedInTemplate>
                            <AddDeal />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.LIST_ORGANIZATIONS}
                    element={
                        <LoggedInTemplate>
                            <ViewAllOrganizations />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.VIEW_ORGANIZATION}
                    element={
                        <LoggedInTemplate>
                            <ViewOrganization />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.ADD_ORGANIZATION}
                    element={
                        <LoggedInTemplate>
                            <AddOrganization />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.VIEW_EVENT}
                    element={
                        <LoggedInTemplate>
                            <ViewEvent />
                        </LoggedInTemplate>
                    }
                />
                <Route
                    path={RouteConstants.ADD_EVENT}
                    element={
                        <LoggedInTemplate>
                            <AddEvent />
                        </LoggedInTemplate>
                    }
                />
                {/* NOTE: All Settings require authentication */}
                <Route
                    path={RouteConstants.USER_SETTINGS}
                    element={
                        <Settings selectedKey="User">
                            <UserSettings />
                        </Settings>
                    }
                />
                {/*  NOTE: Change Password is not valid with auth0 workflows
                <Route
                    path={RouteConstants.CHANGE_PASSWORD_SETTINGS}
                    element={
                        <Settings selectedKey="ChangePassword">
                            <ChangePassword />
                        </Settings>
                    }
                /> */}
            </Routes>
            <ToastContainer />
        </Router>
    )
}
