import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import { DatePicker } from 'antd'
import Text from 'antd/lib/typography/Text'
import dayjs, { Dayjs } from 'dayjs'

import { useEffect, useState } from 'react'

function getMappedDateTimeObject(
    isoDateValue?: string | undefined | null
): Dayjs | undefined {
    let dateTimeValue = undefined
    if (isoDateValue) {
        dateTimeValue = dayjs(isoDateValue)
    }
    return dateTimeValue
}

export default function TextOrInputDateFieldEdit(props: {
    isoDateValue?: string | undefined | null // NOTE: Take iso string as input
    onCommitChange?: (newValue: string | number) => void
}) {
    const [dtObjectTempValue, setDtObjectTempValue] = useState<
        Dayjs | undefined
    >(getMappedDateTimeObject(props.isoDateValue))
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setDtObjectTempValue(getMappedDateTimeObject(props.isoDateValue))
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setDtObjectTempValue(dayjs(props?.isoDateValue))
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    let onDateChange = (newDtObject: Dayjs | null, _dateString: string) => {
        if (newDtObject) {
            setDtObjectTempValue(newDtObject)
        }
    }
    let onCommitChange = () => {
        setEditField(false)
        if (!dtObjectTempValue) {
            setDtObjectTempValue(undefined)
        } else {
            if (props.onCommitChange) {
                props.onCommitChange(dtObjectTempValue.toISOString())
            }
        }
    }

    if (editField) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                <DatePicker
                    // NOTE: This is needed to force the popup window to be below the text box for the birthday
                    popupStyle={{
                        height: '1px',
                    }}
                    allowClear={false}
                    value={dtObjectTempValue}
                    onChange={onDateChange}
                    style={{ width: '100%' }}
                />
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    let displayValue = ''
    if (props.isoDateValue) {
        const dtObject = dayjs(props.isoDateValue)
        displayValue = dtObject.format('YYYY-MM-DD')
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {displayValue}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
