import {
    CreateDeal,
    Deal,
    validateDeal,
    validateCreateDeal,
} from '@dsmaccy/echomodel/dist'
import CacheConstants from './cache_constants'
import { getAllEntities, getEntity, addEntity, editEntity, deleteEntity } from './entity_controller'

const ENTITY_PATH = "deal"
export function getAllDeals(
    userId: string
): Promise<Deal[]> {
    return getAllEntities(ENTITY_PATH, 'getAllDeals', CacheConstants.DEALS, userId)
}

export function getDeal(
    userId: string,
    entityId: string
): Promise<Deal | undefined> {
    return getEntity(ENTITY_PATH, 'getDeal', CacheConstants.DEALS, userId, entityId)
}

export function addDeal(
    userId: string,
    createDeal: CreateDeal
): Promise<string> {
    return addEntity(ENTITY_PATH, 'addDeal', CacheConstants.DEALS, userId, createDeal, validateCreateDeal, validateDeal)
}

export async function editDeal(
    deal: Deal
): Promise<void> {
    return editEntity(ENTITY_PATH, 'editDeal', CacheConstants.DEALS, deal)
}

export async function deleteDeal(
    userId: string,
    entityId: string
): Promise<void> {
    return deleteEntity(ENTITY_PATH, 'deleteDeal', CacheConstants.DEALS, userId, entityId)
}
