import {
    AssetType,
    CreateDeal,
    Deal,
    DealType,
    ExistingConnection,
    IntroductionType,
    Organization,
    Event,
    validateCreateDeal,
    IntroductionOrigin,
} from '@dsmaccy/echomodel'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addDeal, getAllDeals } from '../../controllers/deal_controller'
import MessageConstants from '../../message_constants'
import _ from 'lodash'
import { useGetUserInfo } from '../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'
import { useNavigate } from 'react-router'
import {
    loadEvents,
    loadExistingConnections,
    loadOrganizations,
} from '../../utils/load_entities'
import IntroductionSelect from '../../molecules/introduction_select'
import {
    moneyNumberToString,
    percentageNumberToString,
} from '../../utils/data_mapping_utils'

async function callAddDeal(
    setAddingDeal: (dealBeingAdded: boolean) => void,
    userInfo: UserInfo,
    createDealDTO: CreateDeal
) {
    setAddingDeal(true)

    try {
        let validatedCreateDeal: CreateDeal
        try {
            validatedCreateDeal = validateCreateDeal(createDealDTO)
        } catch (err) {
            MonitorSingleton.sendException(err)
            toast(MessageConstants.BAD_ADD_DEAL_INPUT, {
                type: 'error',
            })
            console.error(err)
            return
        }

        await addDeal(userInfo.id, validatedCreateDeal)
        toast(MessageConstants.addedDealMessage(createDealDTO.name), {
            type: 'success',
        })
    } catch (err) {
        MonitorSingleton.sendException(err)
        console.error(err)
        toast(MessageConstants.UNABLE_TO_ADD_DEAL, { type: 'error' })
    } finally {
        setAddingDeal(false)
    }
}

const defaultDeal: CreateDeal = {
    name: '',
    introductionOrigin: null,
    dealType: null,
    dealValue: null,
    commission: null,
    assetType: null,
    assetValue: null,
}

export default function AddDeal() {
    const [newDeal, setNewDeal] = useState<CreateDeal>(defaultDeal)

    const [addingDeal, setAddingDeal] = useState<boolean>(false)

    const [allConnections, setAllExistingConnections] = useState<
        ExistingConnection[] | undefined
    >()
    const [allEvents, setAllEvents] = useState<Event[] | undefined>()
    const [allOrganizations, setAllOrganizations] = useState<
        Organization[] | undefined
    >()

    const navigate = useNavigate
    const getUserInfo = useGetUserInfo()

    useEffect(() => {
        loadExistingConnections(getUserInfo, navigate).then(
            (existingConnections: ExistingConnection[] | undefined) => {
                setAllExistingConnections(existingConnections)
            }
        )
        loadEvents(getUserInfo, navigate).then(
            (events: Event[] | undefined) => {
                setAllEvents(events)
            }
        )
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
    }, [])

    const submit = async () => {
        if (!newDeal) {
            return
        }
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return
        }
        callAddDeal(setAddingDeal, userInfo, newDeal)
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Add New Deal</Title>
            </div>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                <Form.Item label="Name" name="name">
                    <Input
                        value={newDeal.name}
                        onChange={(event) =>
                            setNewDeal({
                                ...newDeal,
                                name: event.target.value,
                            })
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Deal Type" name="dealType">
                    <Select
                        allowClear
                        value={newDeal.dealType}
                        onChange={(selectValue: DealType | null) => {
                            setNewDeal({
                                ...newDeal,
                                dealType: selectValue,
                            })
                        }}
                    >
                        <Select.Option key={'Buy'} value={'Buy'}>
                            Buy
                        </Select.Option>
                        <Select.Option key={'Sell'} value={'Sell'}>
                            Sell
                        </Select.Option>
                        <Select.Option key={'Consult'} value={'Consult'}>
                            Consult
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Deal Value" name="dealValue">
                    <InputNumber
                        style={{ width: '100%' }}
                        value={newDeal.dealValue ?? 0}
                        formatter={(value) => moneyNumberToString(value)}
                        min={0}
                        onChange={(value) => {
                            setNewDeal({
                                ...newDeal,
                                dealValue: value,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item label="Commission" name="commission">
                    <InputNumber
                        style={{ width: '100%' }}
                        value={newDeal.commission ?? 0}
                        formatter={(value) => percentageNumberToString(value)}
                        min={0}
                        max={100}
                        onChange={(value) => {
                            setNewDeal({
                                ...newDeal,
                                commission: value,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item label="Asset Type" name="assetType">
                    <Select
                        allowClear
                        value={newDeal.assetType}
                        onChange={(selectValue: AssetType | null) => {
                            setNewDeal({
                                ...newDeal,
                                assetType: selectValue,
                            })
                        }}
                    >
                        <Select.Option
                            key={'CommercialRealEstate'}
                            value={'CommercialRealEstate'}
                        >
                            Commercial Real Estate
                        </Select.Option>
                        <Select.Option
                            key={'OperatingCompany'}
                            value={'OperatingCompany'}
                        >
                            Operating Company
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Asset Value" name="assetValue">
                    <InputNumber
                        style={{ width: '100%' }}
                        value={newDeal.assetValue ?? 0}
                        formatter={(value) => moneyNumberToString(value)}
                        min={0}
                        onChange={(value) => {
                            setNewDeal({
                                ...newDeal,
                                assetValue: value,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="How Was This Deal Discovered"
                    name="introductionOrigin"
                >
                    <IntroductionSelect
                        introductionOriginValue={newDeal.introductionOrigin}
                        existingConnections={allConnections}
                        events={allEvents}
                        organizations={allOrganizations}
                        onChange={(
                            newIntroductionOrigin: IntroductionOrigin | null
                        ) =>
                            setNewDeal({
                                ...newDeal,
                                introductionOrigin: newIntroductionOrigin,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={submit}
                        disabled={addingDeal || !newDeal}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
