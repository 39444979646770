import {
    Deal,
    ExistingConnection,
    Organization,
    Event,
} from '@dsmaccy/echomodel'
import { UserInfo } from '../controllers/authentication/UserInfo'
import MessageConstants from '../message_constants'
import { getAllDeals } from '../controllers/deal_controller'
import { toast } from 'react-toastify'
import { validateAndGetUserInfo } from './validation_utils'
import { getAllExistingConnections } from '../controllers/existing_connection_controller'
import { getAllOrganizations } from '../controllers/organization_controller'
import { getAllEvents } from '../controllers/event_controller'
import { MonitorSingleton } from './monitor'

export async function loadDeals(
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void
): Promise<Deal[] | undefined> {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            toast(MessageConstants.UNABLE_TO_LOAD_USER_INFO, {
                type: 'error',
            }) // Currently have 2 toasts -- will keep for now, but may consider changing in the future
            throw new Error(MessageConstants.UNABLE_TO_LOAD_USER_INFO)
        }
        // Remove current deal from list
        const deals = await getAllDeals(userInfo.id)
        if (!deals) {
            throw new Error(MessageConstants.UNABLE_TO_LOAD_DEALS)
        }
        return deals
    } catch (err) {
        toast(MessageConstants.UNABLE_TO_LOAD_DEALS, {
            type: 'error',
        })
    }
}

export async function loadExistingConnections(
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void
): Promise<ExistingConnection[] | undefined> {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            throw new Error(MessageConstants.UNABLE_TO_LOAD_USER_INFO)
        }

        const existingConnections = await getAllExistingConnections(userInfo.id)
        if (!existingConnections) {
            throw new Error(MessageConstants.UNABLE_TO_LOAD_CONNECTIONS)
        }
        return existingConnections
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.UNABLE_TO_LOAD_CONNECTIONS, {
            type: 'error',
        })
    }
}

export async function loadEvents(
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void
): Promise<Event[] | undefined> {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            toast(MessageConstants.UNABLE_TO_LOAD_USER_INFO, {
                type: 'error',
            }) // Currently have 2 toasts -- will keep for now, but may consider changing in the future
            throw new Error(MessageConstants.UNABLE_TO_LOAD_USER_INFO)
        }
        // Remove current connection from list
        const events = await getAllEvents(userInfo.id)
        if (!events) {
            throw new Error(MessageConstants.UNABLE_TO_LOAD_CONNECTIONS)
        }
        return events
    } catch (err) {
        toast(MessageConstants.UNABLE_TO_LOAD_CONNECTIONS, {
            type: 'error',
        })
    }
}

export async function loadOrganizations(
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void
): Promise<Organization[] | undefined> {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            toast(MessageConstants.UNABLE_TO_LOAD_USER_INFO, {
                type: 'error',
            }) // Currently have 2 toasts -- will keep for now, but may consider changing in the future
            throw new Error(MessageConstants.UNABLE_TO_LOAD_USER_INFO)
        }
        // Remove current connection from list
        const organizations = await getAllOrganizations(userInfo.id)
        if (!organizations) {
            throw new Error(MessageConstants.UNABLE_TO_LOAD_CONNECTIONS)
        }
        return organizations
    } catch (err) {
        toast(MessageConstants.UNABLE_TO_LOAD_CONNECTIONS, {
            type: 'error',
        })
    }
}
