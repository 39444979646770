import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'
import { Select } from 'antd'

export default function TextOrSelectEntityFieldEdit<T>(props: {
    value: T | null
    options: T[]
    displayFunction: (value?: T | null) => string
    keyFunction: (value?: T | null) => string
    onCommitChange?: (newValue?: T | null) => void
}) {
    const [optionTempValue, setOptionTempValue] = useState<
        T | null | undefined
    >(props.value)
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setOptionTempValue(props?.value)
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setOptionTempValue(props?.value)
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    let onCommitChange = () => {
        setEditField(false)
        if (props.onCommitChange) {
            if (!optionTempValue) {
                props.onCommitChange(null)
            } else {
                props.onCommitChange(optionTempValue)
            }
        }
    }

    if (editField) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                <Select
                    value={props.keyFunction(optionTempValue)}
                    onChange={(key) => {
                        var newTempKey = props.options.find(
                            (option) => props.keyFunction(option) === key
                        )
                        setOptionTempValue(newTempKey)
                    }}
                    style={{ width: '100%' }}
                >
                    {props.options.map((option) => (
                        <Select.Option
                            key={props.keyFunction(option)}
                            value={props.keyFunction(option)}
                        >
                            {props.displayFunction(option)}
                        </Select.Option>
                    ))}
                </Select>
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {props.displayFunction(props.value)}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
