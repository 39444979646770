import {
    CreateExistingConnection,
    CreateProspectiveConnection,
    ExistingConnection,
    Event,
    Industry,
    IntroductionType,
    Organization,
    PhysicalLocation,
    validateCreateExistingConnection,
} from '@dsmaccy/echomodel'
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addExistingConnection } from '../../controllers/existing_connection_controller'
import MessageConstants from '../../message_constants'
import IndustrySelect from '../../molecules/industry_select'
import LocationSelect from '../../molecules/location_select'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useGetUserInfo } from '../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'
import { useNavigate } from 'react-router'
import {
    loadEvents,
    loadExistingConnections,
    loadOrganizations,
} from '../../utils/load_entities'
import IntroductionSelect from '../../molecules/introduction_select'

async function addConnection(
    setAddingConnection: (connectionBeingAdded: boolean) => void,
    userInfo: UserInfo,
    isExistingConnection: boolean,
    createConnectionDTO: CreateExistingConnection | CreateProspectiveConnection
) {
    setAddingConnection(true)

    try {
        if (isExistingConnection) {
            let validatedCreateExistingConnection: CreateExistingConnection
            try {
                validatedCreateExistingConnection =
                    validateCreateExistingConnection(createConnectionDTO)
            } catch (err) {
                MonitorSingleton.sendException(err)
                toast(MessageConstants.BAD_ADD_CONNECTION_INPUT, {
                    type: 'error',
                })
                console.error(err)
                return
            }

            await addExistingConnection(
                userInfo.id,
                validatedCreateExistingConnection
            )
            toast(
                MessageConstants.addedExistingConnectionMessage(
                    createConnectionDTO.firstName,
                    createConnectionDTO.lastName
                ),
                {
                    type: 'success',
                }
            )
        } else {
            throw new Error('Not implemented')
            // const validatedCreateProspectiveConnection =
            //     validateCreateProspectiveConnection(createConnectionDTO)
            // await addProspectiveConnection(
            //     userInfo.id,
            //     validatedCreateProspectiveConnection
            // )
            // toast(
            //     MessageConstants.addedProspectiveConnectionMessage(
            //         createConnectionDTO.firstName,
            //         createConnectionDTO.lastName
            //     ),
            //     {
            //         type: 'success',
            //     }
            // )
        }
    } catch (err) {
        MonitorSingleton.sendException(err)
        console.error(err)
        toast(MessageConstants.UNABLE_TO_ADD_CONNECTION, { type: 'error' })
    } finally {
        setAddingConnection(false)
    }
}

const defaultExistingConnection: CreateExistingConnection = {
    firstName: '',
    lastName: '',
    industry: null,
    birthday: null,
    estimatedNetWorth: null,
    primaryLocation: null,
    introductionOrigin: null,
}

export default function AddConnection() {
    const [newExistingConnection, setNewExistingConnection] =
        useState<CreateExistingConnection>(defaultExistingConnection)

    const [addingConnection, setAddingConnection] = useState<boolean>(false)
    const [existingConnections, setExistingConnections] = useState<
        ExistingConnection[] | undefined
    >(undefined)

    const [allConnections, setAllExistingConnections] = useState<
        ExistingConnection[] | undefined
    >()
    const [allEvents, setAllEvents] = useState<Event[] | undefined>()
    const [allOrganizations, setAllOrganizations] = useState<
        Organization[] | undefined
    >()

    const navigate = useNavigate
    const getUserInfo = useGetUserInfo()

    useEffect(() => {
        loadExistingConnections(getUserInfo, navigate).then(
            (existingConnections: ExistingConnection[] | undefined) => {
                setAllExistingConnections(existingConnections)
            }
        )
        loadEvents(getUserInfo, navigate).then(
            (events: Event[] | undefined) => {
                setAllEvents(events)
            }
        )
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
    }, [])

    const submit = async () => {
        if (!newExistingConnection) {
            return
        }
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return
        }
        addConnection(
            setAddingConnection,
            userInfo,
            true,
            newExistingConnection
        )
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Add New Connection</Title>
            </div>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                <Form.Item label="First Name" name="firstName">
                    <Input
                        value={newExistingConnection.firstName}
                        onChange={(event) =>
                            setNewExistingConnection({
                                ...newExistingConnection,
                                firstName: event.target.value,
                            })
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName">
                    <Input
                        value={newExistingConnection.lastName}
                        onChange={(event) =>
                            setNewExistingConnection({
                                ...newExistingConnection,
                                lastName: event.target.value,
                            })
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Industry" name="industry">
                    <IndustrySelect
                        industryValue={newExistingConnection.industry}
                        onChange={(industry: Industry | null) =>
                            setNewExistingConnection({
                                ...newExistingConnection,
                                industry: industry,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item label="Birthday" name="birthday">
                    <DatePicker
                        style={{ width: '100%' }}
                        popupStyle={{
                            height: '1px',
                        }}
                        value={dayjs(newExistingConnection.birthday)}
                        onChange={(date: any, dateString: string) => {
                            setNewExistingConnection({
                                ...newExistingConnection,
                                birthday: dateString,
                            })
                        }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Estimated Net Worth" name="worth">
                    <InputNumber
                        style={{ width: '100%' }}
                        value={newExistingConnection.estimatedNetWorth ?? 0}
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        min={0}
                        onChange={(value) => {
                            setNewExistingConnection({
                                ...newExistingConnection,
                                estimatedNetWorth: value,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item label="Primary Location" name="primaryLocation">
                    <LocationSelect
                        locationValue={newExistingConnection?.primaryLocation}
                        onChange={(location: PhysicalLocation | null) => {
                            if (
                                !_.isEqual(
                                    location,
                                    newExistingConnection?.primaryLocation
                                )
                            ) {
                                setNewExistingConnection({
                                    ...newExistingConnection,
                                    primaryLocation: location,
                                })
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label="How Did We Meet" name="introducee">
                    <IntroductionSelect
                        introductionOriginValue={
                            newExistingConnection.introductionOrigin
                        }
                        existingConnections={allConnections}
                        organizations={allOrganizations}
                        events={allEvents}
                        onChange={(selectValue) => {
                            setNewExistingConnection({
                                ...newExistingConnection,
                                introductionOrigin: selectValue,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={submit}
                        disabled={addingConnection || !newExistingConnection}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
