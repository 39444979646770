import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import { Input } from 'antd'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'

export default function TextOrInputFieldEdit(props: {
    stringValue?: string | undefined
    onCommitChange?: (newValue: string | number) => void
}) {
    const [stringTempValue, setStringTempValue] = useState<string | undefined>(
        props.stringValue
    )
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setStringTempValue(props?.stringValue)
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setStringTempValue(props?.stringValue)
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    // NOTE: This UI is better but doesn't take advantage of key presses
    let onTextChange = (event: any) => {
        setStringTempValue(event.target.value)
    }
    let onCommitChange = () => {
        setEditField(false)

        if (!stringTempValue) {
            setStringTempValue(props?.stringValue)
        } else {
            if (props.onCommitChange) {
                props.onCommitChange(stringTempValue)
            }
        }
    }
    let keyPress = (event: any) => {
        if (event.key === 'Enter') {
            onCommitChange()
        }
    }
    if (editField) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                <Input
                    value={stringTempValue}
                    onChange={onTextChange}
                    onKeyPress={keyPress}
                />
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    let displayValue = props.stringValue
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {displayValue}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
