import { PASSWORD_CONDITIONS } from './utils/validation_utils'

export default class MessageConstants {
    public static readonly AUTH_NOT_CONFIGURED =
        'Authentication not configured for this endpoint'
    public static readonly UNRECOGNIZED_ERROR =
        'Unrecognized error occurred.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNRECOGNIZED_MENU_ITEM =
        'Unrecognized menu item.  Please contact admin.'
    public static readonly BAD_INPUT_PHONE_NUMBER_FORMAT =
        'Phone number format not valid.  Please update and try again.'
    public static readonly BAD_SESSION =
        'Session is not valid.  Must log in to get user info.  Try refreshing.  If issue persists, contact admin.'
    public static readonly SESSION_EXPIRED =
        'Session has expired.  Please login.'
    public static readonly ALREADY_LOGGED_IN =
        'User already logged in, must log out first before logging in again.'
    public static readonly BAD_INPUT_NO_USERNAME_IN_SIGN_IN =
        'Username not provided for sign in.'
    public static readonly BAD_INPUT_NO_PASSWORD_IN_SIGN_IN =
        'Invalid password provided for sign in.'
    public static readonly LOGIN_FAILED =
        'Sign in failed.  Please try again.  If issue persists, contact admin.'
    public static readonly GENERIC_LOGIN_FAILURE =
        'Sign in failed for unknown reason.  Please try again later.'
    public static readonly BAD_INPUT_NO_USERNAME_IN_FORGOT_PASSWORD =
        'Username not provided for forgot password.'
    public static readonly BAD_INPUT_UNRECOGNIZED_LOGIN =
        'Invalid username or password.  Please try again.'

    public static readonly UNABLE_TO_LOAD_USER_INFO =
        'Issue loading user information.  Please try again or try refreshing page.  If issue persists, contact admin.'

    // Connection Constants
    public static readonly BAD_ADD_CONNECTION_INPUT =
        'Unable to add connection.  Make sure all fields are filled in and valid.'
    public static readonly UNABLE_TO_LOAD_CONNECTIONS =
        'Issue loading connections.  Please refresh or try again later.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_ADD_CONNECTION =
        'Issue adding connection.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_GET_CONNECTION_INFO =
        'Unable to get connection information.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly FAILED_TO_EDIT_CONNECTION =
        'Failed to update connection information.  Please try again or try refreshing page.  If issue persists, contact admin.'

    // Deal Constants
    public static readonly BAD_ADD_DEAL_INPUT =
        'Unable to add deal.  Make sure all fields are filled in and valid.'
    public static readonly UNABLE_TO_LOAD_DEALS =
        'Issue loading deals.  Please refresh or try again later.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_ADD_DEAL =
        'Issue adding deal.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_GET_DEAL_INFO =
        'Unable to get deal information.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly FAILED_TO_EDIT_DEAL =
        'Failed to update deal information.  Please try again or try refreshing page.  If issue persists, contact admin.'

    // Organization constants
    public static readonly BAD_ADD_ORGANIZATION_INPUT =
        'Unable to add organization.  Make sure all fields are filled in and valid.'
    public static readonly UNABLE_TO_LOAD_ORGANIZATIONS =
        'Issue loading organizations.  Please refresh or try again later.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_ADD_ORGANIZATION =
        'Issue adding organization.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_GET_ORGANIZATION_INFO =
        'Unable to get organization information.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly FAILED_TO_EDIT_ORGANIZATION =
        'Failed to update organization information.  Please try again or try refreshing page.  If issue persists, contact admin.'

    // Event Constants
    public static readonly BAD_ADD_EVENT_INPUT =
        'Unable to add event.  Make sure all fields are filled in and valid.'
    public static readonly UNABLE_TO_LOAD_EVENTS =
        'Issue loading events.  Please refresh or try again later.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_ADD_EVENT =
        'Issue adding event.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_GET_EVENT_INFO =
        'Unable to get event information.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly FAILED_TO_EDIT_EVENT =
        'Failed to update event information.  Please try again or try refreshing page.  If issue persists, contact admin.'

    public static readonly UNABLE_TO_VALIDATE_LOGIN =
        'Unable to validate login.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_UPDATE_USER_INFO =
        'Unable to update user information from the app.  Please contact admin to update information.'
    // 'Unable to update user information.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_LOGIN =
        'An error occurred when trying to sign in.  Please try again or try refreshing page.  If issue persists, contact admin.'
    public static readonly UNABLE_TO_CHANGE_PASSWORD =
        'Failed to change password.  Please make sure old password is correct and try again.  If issue persists, contact admin or perhaps attempt Forgot Password from login'

    public static readonly LOGIN_ERROR_TOO_MANY_ATTEMPTS =
        'Too many failed attempts: Try again later or contact admin.'
    public static readonly LOGIN_ERROR_BAD_CODE =
        'Invalid code provided.  Please try again.'
    public static readonly INVALID_PASSWORD = `Invalid password.  Password must satisfy the following conditions: ${PASSWORD_CONDITIONS}.`
    public static readonly BAD_USER_STATUS_PREVENTING_PASSWORD_RESET = `User status must be updated before resetting password.  Please contact admin.`
    public static readonly BAD_USER_STATUS_EMAIL_CONFIRMATION_REQUIRED =
        'Email confirmation required.  Contact admin.'
    public static readonly BAD_USER_STATUS_MISSING_EMAIL =
        'No email registered for user.  Go to user settings and add an email.'
    public static getBadUserStatusNewPasswordRequired(username: string) {
        return `New password required for user ${username}.`
    }
    public static unableToGetInfoFromUsername(username: string) {
        return `There was an issue getting the credentials for ${username}.  Please try again or try refreshing page.  If issue persists, contact admin.`
    }
    public static noContactInfo(username: string): string {
        return `User ${username} does not have a registered email or phone number.  Contact admin.`
    }
    public static unknownUsernameMessage(username: string) {
        return `User ${username} not found.  Please try another username or contact admin`
    }
    public static addedExistingConnectionMessage(
        firstName: string,
        lastName: string
    ): string {
        return `${firstName} ${lastName} added as an existing connection.`
    }
    public static addedProspectiveConnectionMessage(
        firstName: string,
        lastName: string
    ): string {
        return `${firstName} ${lastName} added as a prospective connection.`
    }
    public static addedDealMessage(dealName: string): string {
        return `${dealName} deal added.`
    }
    public static addedOrganizationMessage(organizationName: string): string {
        return `${organizationName} organization added.`
    }
    public static addedEventMessage(eventName: string): string {
        return `${eventName} event added.`
    }
}
