import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import RouteConstants from '../routes_constants'

export function PageNotFoundPage(_props: any) {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary">
                    <Link to={RouteConstants.HOME}>Back Home</Link>
                </Button>
            }
        />
    )
}
