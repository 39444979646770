import {
    Event,
    Organization,
    PhysicalLocation,
    validateEvent,
} from '@dsmaccy/echomodel'
import { Col, Row, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editEvent, getEvent } from '../../../controllers/event_controller'
import MessageConstants from '../../../message_constants'
import TextOrInputFieldEdit from '../../../molecules/text_input_wrappers/text_or_input_field_edit'
import Loading from '../../../molecules/loading'
import { isEqual } from 'lodash-es'
import { useGetUserInfo } from '../../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../../utils/monitor'
import { validateAndGetUserInfo } from '../../../utils/validation_utils'
import TextOrSelectFieldEdit from '../../../molecules/text_input_wrappers/text_or_select_field_edit'
import { loadOrganizations } from '../../../utils/load_entities'
import TextOrInputDateFieldEdit from '../../../molecules/text_input_wrappers/text_or_input_date_field_edit'
import TextOrInputLocationFieldEdit from '../../../molecules/text_input_wrappers/text_or_input_location_field_edit'
import TextOrSelectEntityFieldEdit from '../../../molecules/text_input_wrappers/text_or_select_entity_field_edit'

async function updateEvent(
    newEvent: Event | undefined,
    oldEvent: Event | undefined,
    setEvent: (event: Event) => void
) {
    try {
        if (!newEvent || !oldEvent || isEqual(newEvent, oldEvent)) {
            return
        }

        const event = validateEvent(newEvent)

        await editEvent(event)
        setEvent(event)
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.FAILED_TO_EDIT_EVENT, { type: 'error' })
    }
}

let loadSingleEvent = async (
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void,
    entityId: string
): Promise<Event | undefined> => {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return undefined
        }
        const event = await getEvent(userInfo.id, entityId)
        if (!event) {
            throw new Error(MessageConstants.UNABLE_TO_GET_EVENT_INFO)
        }
        return event
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.UNABLE_TO_GET_EVENT_INFO, {
            type: 'error',
        })
    }
}

export default function ViewEvent() {
    const getUserInfo = useGetUserInfo()
    const { entityId } = useParams()

    const [currentEvent, setCurrentEvent] = useState<Event | undefined>()
    // const [userId, setUserId] = useState<string | undefined>(undefined)
    const [startingEvent, setStartingEvent] = useState<Event>()

    const [doneLoading, setDoneLoading] = useState<boolean>(false)

    const [allOrganizations, setAllOrganizations] = useState<
        Organization[] | undefined
    >()

    const navigate = useNavigate()

    useEffect(() => {
        if (!entityId) {
            return
        }
        loadSingleEvent(getUserInfo, navigate, entityId).then((event) => {
            if (event) {
                setStartingEvent(event)
                setCurrentEvent(event)
                setDoneLoading(true)
            }
        })
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
    }, [])

    if (!entityId) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Event {entityId} not found</Title>
            </div>
        )
    }

    if (!doneLoading) {
        return <Loading />
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Viewing Event {startingEvent?.name}</Title>
            </div>
            <Space
                direction="vertical"
                size="large"
                style={{ display: 'flex' }}
            >
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Name:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputFieldEdit
                            stringValue={currentEvent?.name}
                            onCommitChange={async (name) => {
                                setCurrentEvent({
                                    ...currentEvent,
                                    name: name,
                                } as Event)
                                updateEvent(
                                    {
                                        ...startingEvent,
                                        name: name,
                                    } as Event,
                                    startingEvent,
                                    setStartingEvent
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Date:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputDateFieldEdit
                            isoDateValue={currentEvent?.date}
                            onCommitChange={async (newDate) => {
                                setCurrentEvent({
                                    ...currentEvent,
                                    date: newDate,
                                } as Event)
                                updateEvent(
                                    {
                                        ...startingEvent,
                                        date: newDate,
                                    } as Event,
                                    startingEvent,
                                    setStartingEvent
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Location:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputLocationFieldEdit
                            locationValue={currentEvent?.location}
                            onCommitChange={async (
                                newLocation: PhysicalLocation | null
                            ) => {
                                setCurrentEvent({
                                    ...currentEvent,
                                    location: newLocation,
                                } as Event)
                                updateEvent(
                                    {
                                        ...startingEvent,
                                        location: newLocation,
                                    } as Event,
                                    startingEvent,
                                    setStartingEvent
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Organization:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrSelectEntityFieldEdit
                            value={allOrganizations?.find(
                                (organization) =>
                                    organization.entityId ===
                                    currentEvent?.organizationId
                            )}
                            options={allOrganizations ?? []}
                            onCommitChange={async (
                                organization //: string | null
                            ) => {
                                setCurrentEvent({
                                    ...currentEvent,
                                    organizationId: organization?.entityId,
                                } as Event)
                                updateEvent(
                                    {
                                        ...startingEvent,
                                        organizationId: organization?.entityId,
                                    } as Event,
                                    startingEvent,
                                    setStartingEvent
                                )
                            }}
                            displayFunction={(value) => value?.name ?? ''}
                            keyFunction={(value) => value?.entityId ?? ''}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
            </Space>
        </div>
    )
}
