import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import RouteConstants from '../routes_constants'
import { useNavigate } from 'react-router-dom'
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'
import { MenuClickEventHandler } from '../types/ant_types'

function generateMenuItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    onClick?: MenuClickEventHandler,
    children?: MenuItemType[],
    type?: 'group'
): MenuItemType {
    return {
        key,
        icon,
        children,
        label,
        type,
        onClick,
    } as MenuItemType
}

const HOME_KEY = 'home'
const CONNECTIONS_KEY = 'connections'
const ORGANIZATION_KEY = 'organizations'
const DEALS_KEY = 'deals'

interface MenuBarProps {
    collapsed?: boolean
    setCollapsed?: (collapsed: boolean) => void
}

export default function MenuBar(props: MenuBarProps) {
    const navigate = useNavigate()

    function generateNavFunction(route: string) {
        return () => {
            navigate(route)
        }
    }

    const items = [
        generateMenuItem(
            'Home',
            HOME_KEY,
            <HomeOutlined />,
            generateNavFunction(RouteConstants.HOME)
        ),
        generateMenuItem(
            'Connections',
            CONNECTIONS_KEY,
            <UserOutlined />,
            generateNavFunction(RouteConstants.LIST_CONNECTIONS)
        ),
        generateMenuItem(
            'Organizations',
            ORGANIZATION_KEY,
            <UserOutlined />,
            generateNavFunction(RouteConstants.LIST_ORGANIZATIONS)
        ),
        generateMenuItem(
            'Deals',
            DEALS_KEY,
            <UserOutlined />,
            generateNavFunction(RouteConstants.LIST_DEALS)
        ),
    ]

    return (
        <Menu
            mode="inline"
            inlineCollapsed={props.collapsed}
            items={items}
            theme="dark"
            selectable={false}
        />
    )
}
