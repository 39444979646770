import { Button, Col, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../routes_constants'
import { grey } from '@ant-design/colors'
import {
    LoginOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
} from '@ant-design/icons'
import { useSignOut } from '../controllers/authentication/clients/auth0'

interface SiteHeaderProps {
    loggedIn: boolean
    collapsed?: boolean
    setCollapsed?: (collapsed: boolean) => void
}

export default function SiteHeader(props: SiteHeaderProps) {
    const SITE_HEADER = 'Echo'
    const SITE_SUB_TITLE = 'Connection Management'

    let navigate = useNavigate()
    const signOut = useSignOut()
    let logoutOnClick = () => {
        signOut()
    }
    let loginOnClick = () => {
        navigate(RouteConstants.LOGIN, { replace: false })
    }
    let settingsOnClick = () => {
        navigate(RouteConstants.USER_SETTINGS, { replace: false })
    }

    const extras = []
    if (!props.loggedIn) {
        extras.push(
            <Button
                key="login"
                style={{ marginLeft: '5px', marginRight: '5px' }}
                type="primary"
                icon={<LoginOutlined />}
                onClick={loginOnClick}
            >
                {/* Login */}
            </Button>
        )
    } else {
        extras.push(
            <Button
                key="settings"
                style={{ marginLeft: '5px', marginRight: '5px' }}
                type="primary"
                icon={<SettingOutlined />}
                onClick={settingsOnClick}
            >
                {/* Settings */}
            </Button>
        )
        extras.push(
            <Button
                key="logout"
                onClick={logoutOnClick}
                style={{ marginLeft: '5px', marginRight: '5px' }}
                type="primary"
                icon={<LogoutOutlined />}
            >
                {/* Logout */}
            </Button>
        )
    }

    return (
        <Row wrap={false} gutter={[8, 8]} style={{ width: '100%' }}>
            <Col span={2} style={{ minWidth: '50px' }}>
                <Button
                    type="primary"
                    onClick={() => {
                        if (props.setCollapsed)
                            props.setCollapsed(!props.collapsed)
                    }}
                    style={{ marginRight: '6px' }}
                >
                    {props.collapsed ? (
                        <MenuUnfoldOutlined />
                    ) : (
                        <MenuFoldOutlined />
                    )}
                </Button>
            </Col>
            <Col span={18}>
                <Row align="bottom" wrap={false}>
                    <Typography.Title
                        level={4}
                        style={{
                            color: grey[0],
                            marginRight: '12px',
                            marginBottom: '0px',
                        }}
                    >
                        {SITE_HEADER}
                    </Typography.Title>
                    <Typography.Title
                        level={5}
                        ellipsis={{ rows: 1 }}
                        style={{
                            color: grey[0],
                            marginRight: '12px',
                            marginBottom: '0px',
                        }}
                    >
                        {SITE_SUB_TITLE}
                    </Typography.Title>
                </Row>
            </Col>
            <Col span={4}>
                <Row justify="end" wrap={false}>
                    {extras.map((extra) => (
                        <Col key={extra.key + '_col'}>{extra}</Col>
                    ))}
                </Row>
            </Col>
        </Row>
    )
}
