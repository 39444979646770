import { Industries, Industry } from '@dsmaccy/echomodel'
import {
    IndustryMajorType,
    validateIndustry,
} from '@dsmaccy/echomodel/dist/valuetypes/industries'
import { Cascader } from 'antd'
import { addSpacesForCamelCases } from '../utils/data_mapping_utils'
import { MonitorSingleton } from '../utils/monitor'

interface Option {
    value: string
    label: any
    children?: Option[]
    disabled?: boolean
}

export default function IndustrySelect(props: {
    industryValue?: Industry | null
    onChange: (industry: Industry | null) => void
}) {
    const cascaderOptions = Object.getOwnPropertyNames(IndustryMajorType).map(
        (industryMajorType: string) => {
            const minorTypes: string[] = Object.getOwnPropertyNames(
                Industries[industryMajorType as keyof Industries]
            )
            const children: Option[] = minorTypes.map(
                (industryMinorType: string) => {
                    return {
                        value: industryMinorType,
                        label: addSpacesForCamelCases(industryMinorType),
                    }
                }
            )
            return {
                value: industryMajorType,
                label: addSpacesForCamelCases(industryMajorType),
                children,
            }
        }
    )
    let value: string[] = []
    try {
        const industry = validateIndustry(props.industryValue)
        value = [industry.type, industry.subtype]
    } catch (err) {
        MonitorSingleton.sendException(err)
    }

    const onChange = (
        value: any[] | undefined,
        _selectOptions: (any | Option)[]
    ): void => {
        if (!value || value.length < 2) {
            props.onChange(null)
            return
        }

        let validatedIndustry: Industry | null = null
        try {
            validatedIndustry = validateIndustry({
                type: value[0],
                subtype: value[1],
            })
        } catch (err) {
            MonitorSingleton.sendException(err)
            return
        }

        props.onChange(validatedIndustry)
    }

    return (
        <Cascader
            showSearch
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            options={cascaderOptions}
            placeholder="Select Industry"
        />
    )
}
