import { Button, Form } from 'antd'
import { useEffect } from 'react'

import { useNavigate } from 'react-router'
import Title from 'antd/lib/typography/Title'
import { useSignIn } from '../controllers/authentication/clients/auth0'
import { UserStatus } from '../controllers/UserStatus'
import RouteConstants from '../routes_constants'
import MessageConstants from '../message_constants'
import { toast } from 'react-toastify'
import { MonitorSingleton } from '../utils/monitor'

export default function Auth0LoginPage() {
    const signIn = useSignIn()
    const navigate = useNavigate()
    async function loginProcedure() {
        try {
            const userStatus: UserStatus = await signIn()
            switch (userStatus) {
                case UserStatus.Good:
                    navigate(RouteConstants.HOME, { replace: false })
                    break
                default:
                    console.error(`userStatus default: ${userStatus}`)
                    toast(MessageConstants.UNABLE_TO_LOGIN, { type: 'error' })
            }
        } catch (err: any) {
            // This can occur if the popup is closed or if there was an issue logging in
            if (err.message === 'Popup closed') {
                console.warn('Signin popup closed')
            } else {
                console.error(err)
                MonitorSingleton.sendException(err)
                toast(MessageConstants.UNABLE_TO_LOGIN, { type: 'error' })
            }
        }
    }

    const nonLabelWrapperProps = {
        // NOTE: Do not have anything for xs: it will automatically stretch and restructure well
        // xs: { span: 8, offset: 8 },
        sm: { span: 8, offset: 8 },
        md: { span: 8, offset: 8 },
        lg: { span: 8, offset: 8 },
        xl: { span: 8, offset: 8 },
        xxl: { span: 8, offset: 8 },
    }

    useEffect(() => {
        loginProcedure()
    }, [])

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Login</Title>
            </div>
            <Form>
                <Form.Item wrapperCol={nonLabelWrapperProps}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={loginProcedure}
                        style={{ width: '100%' }}
                    >
                        Login using Auth0
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
