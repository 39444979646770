import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space } from 'antd'
import { FilterConfirmProps } from 'antd/lib/table/interface'

type FilterDropdownProps = {
    setSelectedKeys: (selectedKeys: React.Key[]) => void
    selectedKeys: React.Key[]
    confirm: (param?: FilterConfirmProps | undefined) => void
    clearFilters: (() => void) | undefined
    dataColumnLabel: string
    handleSearch: (
        selectedKeys: React.Key[],
        confirm: (param?: FilterConfirmProps | undefined) => void,
        dataColumnLabel: string
    ) => void
    handleReset: (clearFilters: (() => void) | undefined) => void
}

export default function FilterDropdown(props: FilterDropdownProps) {
    return (
        <div style={{ padding: 8 }}>
            <Input
                // ref={(node) => {
                //     this.searchInput = node
                // }}
                placeholder={`Search ${props.dataColumnLabel}`}
                value={props.selectedKeys[0]}
                onChange={(e) =>
                    props.setSelectedKeys(
                        e.target.value ? [e.target.value] : []
                    )
                }
                onPressEnter={() =>
                    props.handleSearch(
                        props.selectedKeys,
                        props.confirm,
                        props.dataColumnLabel
                    )
                }
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() =>
                        props.handleSearch(
                            props.selectedKeys,
                            props.confirm,
                            props.dataColumnLabel
                        )
                    }
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => props.handleReset(props.clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        props.handleSearch(
                            props.selectedKeys,
                            props.confirm,
                            props.dataColumnLabel
                        )
                        // props.confirm({ closeDropdown: false })
                        // this.setState({
                        //     searchText: selectedKeys[0],
                        //     searchedColumn: dataIndex,
                        // })
                    }}
                >
                    Filter
                </Button>
            </Space>
        </div>
    )
}
