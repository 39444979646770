import * as Sentry from '@sentry/react'
import { Transaction } from '@sentry/types'
import { BrowserTracing } from '@sentry/tracing'

class Monitor {
    initialize() {
        try {
            // NOTE: The following methods should be used for logging:
            // Sentry.captureException(err);
            // Sentry.captureMessage(message, <optional log level>);
            // See Monitor class in util
            Sentry.init({
                dsn: `https://${process.env.REACT_APP_SENTRY_KEY}@${process.env.REACT_APP_SENTRY_SUBDOMAIN}.sentry.io/${process.env.REACT_APP_SENTRY_PROJECTID}`,
                integrations: [new BrowserTracing()],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0, // TODO: CHANGE
            })
            Sentry.configureScope((scope) => {
                scope.setExtra('Environment', process.env.REACT_APP_ENVIRONMENT)
            })
        } catch (err) {
            console.error('In monotor init:')
            console.error(err)
        }
    }
    public async wrapTransaction(
        identifier: string,
        innerFunction: (transaction?: Transaction) => Promise<any>
    ): Promise<any> {
        let transaction = undefined
        // Start transaction
        try {
            transaction = Sentry.startTransaction({
                name: identifier,
            })
        } catch (err) {
            console.error('Error in monitor transaction: creating transaction:')
            console.error(err)
        }

        // Run internal function
        try {
            return await innerFunction(transaction)
        } catch (err) {
            throw err
        } finally {
            // Finalize transaction
            try {
                transaction?.finish()
            } catch (err) {
                console.error(
                    'Error in monitor transaction: finishing transaction:'
                )
                console.error(err)
            }
        }
    }

    public sendMessage(message: string, severe: boolean = false) {
        try {
            const severity = severe ? "error" : undefined
            Sentry.captureMessage(message, severity)
        } catch (err) {
            console.error('In monotor message:')
            console.error(err)
        }
    }

    public sendException(err: any) {
        try {
            Sentry.captureException(err)
        } catch (err) {
            console.error('In monotor exception:')
            console.error(err)
        }
    }
}
const MonitorSingleton = new Monitor()
export { MonitorSingleton }
