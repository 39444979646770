import {
    Deal,
    DealType,
    ExistingConnection,
    IntroductionOrigin,
    Organization,
    validateDeal,
    Event,
} from '@dsmaccy/echomodel'
import { Col, Row, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editDeal, getDeal } from '../../controllers/deal_controller'
import MessageConstants from '../../message_constants'
import TextOrInputFieldEdit from '../../molecules/text_input_wrappers/text_or_input_field_edit'
import TextOrInputNumberFieldEdit from '../../molecules/text_input_wrappers/text_or_input_number_field_edit'
import Loading from '../../molecules/loading'
import { isEqual } from 'lodash-es'
import TextOrInputIntroductionOriginFieldEdit from '../../molecules/text_input_wrappers/text_or_input_introduction_origin_field_edit'
import { useGetUserInfo } from '../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'
import TextOrSelectFieldEdit from '../../molecules/text_input_wrappers/text_or_select_field_edit'
import {
    loadEvents,
    loadExistingConnections,
    loadOrganizations,
} from '../../utils/load_entities'

async function updateDeal(
    newDeal: Deal | undefined,
    oldDeal: Deal | undefined,
    setDeal: (deal: Deal) => void
) {
    try {
        if (!newDeal || !oldDeal || isEqual(newDeal, oldDeal)) {
            return
        }

        const deal = validateDeal(newDeal)

        await editDeal(deal)
        setDeal(deal)
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.FAILED_TO_EDIT_DEAL, { type: 'error' })
    }
}

let loadSingleDeal = async (
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void,
    entityId: string
): Promise<Deal | undefined> => {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return undefined
        }
        const deal = await getDeal(userInfo.id, entityId)
        if (!deal) {
            throw new Error(MessageConstants.UNABLE_TO_GET_DEAL_INFO)
        }
        return deal
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.UNABLE_TO_GET_DEAL_INFO, {
            type: 'error',
        })
    }
}

export default function ViewDeal() {
    const getUserInfo = useGetUserInfo()
    const { entityId } = useParams()

    const [currentDeal, setCurrentDeal] = useState<Deal | undefined>()
    // const [userId, setUserId] = useState<string | undefined>(undefined)
    const [startingDeal, setStartingDeal] = useState<Deal>()

    const [doneLoading, setDoneLoading] = useState<boolean>(false)

    const [allConnections, setAllExistingConnections] = useState<
        ExistingConnection[] | undefined
    >()
    const [allEvents, setAllEvents] = useState<Event[] | undefined>()
    const [allOrganizations, setAllOrganizations] = useState<
        Organization[] | undefined
    >()

    const navigate = useNavigate()

    useEffect(() => {
        if (!entityId) {
            return
        }
        loadSingleDeal(getUserInfo, navigate, entityId).then((deal) => {
            if (deal) {
                setStartingDeal(deal)
                setCurrentDeal(deal)
                setDoneLoading(true)
            }
        })
        loadExistingConnections(getUserInfo, navigate).then(
            (existingConnections: ExistingConnection[] | undefined) => {
                setAllExistingConnections(existingConnections)
            }
        )
        loadEvents(getUserInfo, navigate).then(
            (events: Event[] | undefined) => {
                setAllEvents(events)
            }
        )
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
    }, [])

    if (!entityId) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Deal {entityId} not found</Title>
            </div>
        )
    }

    if (!doneLoading) {
        return <Loading />
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Viewing Deal {startingDeal?.name}</Title>
            </div>
            <Space
                direction="vertical"
                size="large"
                style={{ display: 'flex' }}
            >
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Name:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputFieldEdit
                            stringValue={currentDeal?.name}
                            onCommitChange={async (name) => {
                                setCurrentDeal({
                                    ...currentDeal,
                                    name: name,
                                } as Deal)
                                updateDeal(
                                    {
                                        ...startingDeal,
                                        name: name,
                                    } as Deal,
                                    startingDeal,
                                    setStartingDeal
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Deal Type:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrSelectFieldEdit
                            optionValue={currentDeal?.dealType}
                            options={['Buy', 'Sell', 'Consult', null]}
                            onCommitChange={async (
                                optionValue: string | null
                            ) => {
                                const newDealType =
                                    optionValue as DealType | null
                                setCurrentDeal({
                                    ...currentDeal,
                                    dealType: newDealType,
                                } as Deal)
                                updateDeal(
                                    {
                                        ...startingDeal,
                                        dealType: newDealType,
                                    } as Deal,
                                    startingDeal,
                                    setStartingDeal
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Deal Value:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputNumberFieldEdit
                            numberValue={currentDeal?.dealValue}
                            money
                            onCommitChange={async (newDealValue) => {
                                setCurrentDeal({
                                    ...currentDeal,
                                    dealValue: newDealValue,
                                } as Deal)
                                updateDeal(
                                    {
                                        ...startingDeal,
                                        dealValue: newDealValue,
                                    } as Deal,
                                    startingDeal,
                                    setStartingDeal
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Commission:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputNumberFieldEdit
                            percentage
                            numberValue={currentDeal?.commission}
                            onCommitChange={async (newCommission) => {
                                setCurrentDeal({
                                    ...currentDeal,
                                    commission: newCommission,
                                } as Deal)
                                updateDeal(
                                    {
                                        ...startingDeal,
                                        commission: newCommission,
                                    } as Deal,
                                    startingDeal,
                                    setStartingDeal
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Asset Type:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrSelectFieldEdit
                            optionValue={currentDeal?.assetType}
                            options={['Buy', 'Sell', 'Consult', null]}
                            onCommitChange={async (
                                optionValue: string | null
                            ) => {
                                const newAssetType =
                                    optionValue as DealType | null
                                setCurrentDeal({
                                    ...currentDeal,
                                    assetType: newAssetType,
                                } as Deal)
                                updateDeal(
                                    {
                                        ...startingDeal,
                                        assetType: newAssetType,
                                    } as Deal,
                                    startingDeal,
                                    setStartingDeal
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Asset Value:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputNumberFieldEdit
                            numberValue={currentDeal?.assetValue}
                            money
                            onCommitChange={async (newAssetValue) => {
                                setCurrentDeal({
                                    ...currentDeal,
                                    assetValue: newAssetValue,
                                } as Deal)
                                updateDeal(
                                    {
                                        ...startingDeal,
                                        assetValue: newAssetValue,
                                    } as Deal,
                                    startingDeal,
                                    setStartingDeal
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>How was the Deal Found:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputIntroductionOriginFieldEdit
                            entityIdToOmit={currentDeal?.entityId}
                            introductionOrigin={
                                currentDeal?.introductionOrigin ?? null
                            }
                            existingConnections={allConnections}
                            events={allEvents}
                            organizations={allOrganizations}
                            onCommitChange={async (
                                newIntroductionOrigin: IntroductionOrigin | null
                            ) => {
                                if (!startingDeal) {
                                    return
                                }

                                if (
                                    startingDeal.introductionOrigin?.id !==
                                    newIntroductionOrigin?.id
                                ) {
                                    return
                                }

                                setCurrentDeal({
                                    ...currentDeal,
                                    introductionOrigin: newIntroductionOrigin,
                                } as Deal)
                                await updateDeal(
                                    {
                                        ...startingDeal,
                                        introductionOrigin:
                                            newIntroductionOrigin,
                                    },
                                    startingDeal,
                                    setStartingDeal
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
            </Space>
        </div>
    )
}
