import {
    CreateExistingConnection,
    ExistingConnection,
    validateExistingConnection,
    validateCreateExistingConnection,
} from '@dsmaccy/echomodel/dist'
import CacheConstants from './cache_constants'
import { getAllEntities, getEntity, addEntity, editEntity, deleteEntity } from './entity_controller'

const ENTITY_PATH = 'existing-connection'
export async function getAllExistingConnections(
    userId: string
): Promise<ExistingConnection[]> {
    return getAllEntities(ENTITY_PATH, 'getAllExistingConnections', CacheConstants.EXISTING_CONNECTIONS, userId)
}

export async function getExistingConnection(
    userId: string,
    entityId: string
): Promise<ExistingConnection | undefined> {
    return getEntity(ENTITY_PATH, 'getExistingConnection', CacheConstants.EXISTING_CONNECTIONS, userId, entityId)
}

export async function addExistingConnection(
    userId: string,
    createExistingConnection: CreateExistingConnection
): Promise<string> {
    return addEntity(ENTITY_PATH, 'addExistingConnection', CacheConstants.EXISTING_CONNECTIONS, userId, createExistingConnection, validateCreateExistingConnection, validateExistingConnection)
}

export async function editExistingConnection(
    existingConnection: ExistingConnection
): Promise<void> {
    return editEntity(ENTITY_PATH, 'editExistingConnection', CacheConstants.EXISTING_CONNECTIONS, existingConnection)
}

export async function deleteExistingConnection(
    userId: string,
    entityId: string
): Promise<void> {
    return deleteEntity(ENTITY_PATH, 'deleteExistingConnection', CacheConstants.EXISTING_CONNECTIONS, userId, entityId)
}
