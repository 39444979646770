import {
    CreateOrganization,
    Organization,
    validateOrganization,
    validateCreateOrganization,
} from '@dsmaccy/echomodel/dist'
import CacheConstants from './cache_constants'
import { getAllEntities, getEntity, addEntity, editEntity, deleteEntity } from './entity_controller'

const ENTITY_PATH = "organization"
export function getAllOrganizations(
    userId: string
): Promise<Organization[]> {
    return getAllEntities(ENTITY_PATH, 'getAllOrganizations', CacheConstants.ORGANIZATIONS, userId)
}

export function getOrganization(
    userId: string,
    entityId: string
): Promise<Organization | undefined> {
    return getEntity(ENTITY_PATH, 'getOrganization', CacheConstants.ORGANIZATIONS, userId, entityId)
}

export function addOrganization(
    userId: string,
    createOrganization: CreateOrganization
): Promise<string> {
    return addEntity(ENTITY_PATH, 'addOrganization', CacheConstants.ORGANIZATIONS, userId, createOrganization, validateCreateOrganization, validateOrganization)
}

export async function editOrganization(
    organization: Organization
): Promise<void> {
    return editEntity(ENTITY_PATH, 'editOrganization', CacheConstants.ORGANIZATIONS, organization)
}

export async function deleteOrganization(
    userId: string,
    entityId: string
): Promise<void> {
    return deleteEntity(ENTITY_PATH, 'deleteOrganization', CacheConstants.ORGANIZATIONS, userId, entityId)
}
