import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import MessageConstants from '../../../message_constants'
import { Cache } from 'aws-amplify'
import CacheConstants from '../../cache_constants'
import { validatePassword } from '../../../utils/validation_utils'
import { UserInfo } from '../UserInfo'
import { SessionState } from '../SessionState'
import { UserStatus } from '../../UserStatus'
import { MonitorSingleton } from '../../../utils/monitor'

export const authCookieName = 'echofe-auth-token-amplify'

export function useChangePassword() {
    return async (
        oldPassword: string,
        newPassword: string
    ): Promise<Boolean> => {
        if (!validatePassword(newPassword)) {
            return false
        }

        try {
            MonitorSingleton.sendMessage('Password being changed')
            const sessionResult: CognitoUserSession =
                await Auth.currentSession()
            if (!sessionResult.isValid()) {
                throw new Error(MessageConstants.BAD_SESSION)
            }

            let currentUser = await Auth.currentAuthenticatedUser()

            await Auth.changePassword(currentUser, oldPassword, newPassword)
            return true
        } catch (err) {
            MonitorSingleton.sendException(err)
            throw err
        }
    }
}

export async function getSessionToken(): Promise<string | undefined> {
    try {
        const sessionResult: CognitoUserSession = await Auth.currentSession()
        if (sessionResult.isValid()) {
            return sessionResult.getAccessToken().getJwtToken()
        } else {
            return undefined
        }
    } catch (err) {
        MonitorSingleton.sendException(err)
    }
    return undefined
}

/*
userInfo: {"id":"us-west-1:ee9b22f3-ad0f-4435-98e7-eef25ea88df3","username":"test3","attributes":{"sub":"66428b89-3f03-4c6f-866a-50ed2318714c","email_verified":false,"email":"dsmaccy@rocketmail.com"}}
*/
export function useGetUserInfo(): () => Promise<UserInfo | undefined> {
    return async (): Promise<UserInfo | undefined> => {
        try {
            const sessionResult: CognitoUserSession =
                await Auth.currentSession()

            if (sessionResult.isValid()) {
                let userInfo: CognitoUser = await Auth.currentUserInfo()

                return {
                    id: (userInfo as any).attributes.sub,
                    username: (userInfo as any).username,
                    email: (userInfo as any).attributes.email,
                    phoneNumber: (userInfo as any).attributes.phoneNumber,
                }
            } else {
                return undefined
            }
        } catch (err) {
            MonitorSingleton.sendException(err)
        }
        return undefined
    }
}

export function useGetSessionState(): () => Promise<SessionState> {
    return async (): Promise<SessionState> => {
        try {
            const sessionResult: CognitoUserSession =
                await Auth.currentSession()
            if (sessionResult.isValid()) {
                return SessionState.LoggedIn
            } else {
                return SessionState.LoggedOut
            }
        } catch (err) {
            if (err !== 'No current user') {
                MonitorSingleton.sendException(err)
            }
        }
        return SessionState.LoggedOut
    }
}

export function useSignIn(): (
    username: string | undefined,
    password: string | undefined,
    newPassword: string | undefined
) => Promise<UserStatus> {
    return async (
        username: string | undefined,
        password: string | undefined,
        newPassword: string | undefined
    ) => {
        if (username === undefined) {
            throw new Error(MessageConstants.BAD_INPUT_NO_USERNAME_IN_SIGN_IN)
        } else if (password === undefined) {
            throw new Error(MessageConstants.BAD_INPUT_NO_PASSWORD_IN_SIGN_IN)
        }

        try {
            const sessionResult: CognitoUserSession =
                await Auth.currentSession()
            if (sessionResult.isValid()) {
                throw new Error(MessageConstants.ALREADY_LOGGED_IN)
            }
        } catch (err) {
            // This is the happy case: this should return an error for when the user is actually authenticated
        }

        let user: CognitoUser = await Auth.signIn(username, password)

        if (!user) {
            throw new Error(MessageConstants.GENERIC_LOGIN_FAILURE)
        } else {
            if ((user as any).challengeName === 'NEW_PASSWORD_REQUIRED') {
                if (!newPassword) {
                    return UserStatus.RequiresPasswordChange
                }
                await passwordChangePromise(user, newPassword)
                return UserStatus.Good
            }

            return UserStatus.Good
        }
    }
}

export function useSignOut(): () => Promise<any> {
    return async () => {
        Cache.removeItem(CacheConstants.EXISTING_CONNECTIONS)
        return await Auth.signOut()
    }
}

export function useForgotPassword(): (username: string) => Promise<any> {
    return async (username) => {
        try {
            if (!username) {
                throw new Error(
                    MessageConstants.BAD_INPUT_NO_USERNAME_IN_FORGOT_PASSWORD
                )
            }
            if (username !== undefined) {
                let response = await Auth.forgotPassword(username, undefined)
                return response
            }
        } catch (err) {
            MonitorSingleton.sendException(err)
            throw err
        }
    }
}

export function useUpdateUserAttributes() {
    return async (attributes: { email?: string; phone_number?: string }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const sessionResult: CognitoUserSession =
                    await Auth.currentSession()

                if (sessionResult.isValid()) {
                    const attributesUpdateArray = []
                    if (attributes.email) {
                        attributesUpdateArray.push({
                            Name: 'email',
                            Value: attributes.email,
                        })
                    }
                    if (attributes.phone_number) {
                        attributesUpdateArray.push({
                            Name: 'phone_number',
                            Value: attributes.phone_number,
                        })
                    }
                    if (attributesUpdateArray.length === 0) {
                        resolve('')
                    }

                    let user: CognitoUser = await Auth.currentUserPoolUser()
                    user.updateAttributes(
                        attributesUpdateArray,
                        (err, result) => {
                            if (err) {
                                MonitorSingleton.sendException(err)
                                reject(err)
                            } else {
                                resolve(result)
                            }
                        }
                    )
                } else {
                    reject('session not valid')
                }
            } catch (err) {
                MonitorSingleton.sendException(err)
                reject(err)
            }
        })
    }
}

export function useConfirmPassword() {
    return async (
        username: string,
        verificationCode: string,
        newPassword: string
    ) => {
        try {
            const result = await Auth.forgotPasswordSubmit(
                username,
                verificationCode,
                newPassword
            )
            return result
        } catch (err) {
            MonitorSingleton.sendException(err)
            throw err
        }
    }
}

function passwordChangePromise(
    user: CognitoUser,
    newPassword: string
): Promise<any> {
    return new Promise((resolve, reject) => {
        user.completeNewPasswordChallenge(newPassword, undefined, {
            onSuccess: (
                // session: CognitoUserSession,
                session: any,
                userConfirmationNecessary?: boolean
            ) => {
                resolve(
                    `In onSuccess: ${JSON.stringify(
                        session
                    )}, ${userConfirmationNecessary}`
                )
            },
            onFailure: (err: any) => {
                if ('Invalid attributes given' in err) {
                    reject(UserStatus.RequiresUserSettingsChange)
                }
                reject(`In onFailure: ${err}`)
            },
            newPasswordRequired: (
                userAttributes: any,
                requiredAttributes: any
            ) => {
                reject(
                    `In newPasswordRequired: ${userAttributes}, ${requiredAttributes}`
                )
            },
            mfaRequired: (challengeName: any, challengeParameters: any) => {
                reject(
                    `In mfa required: ${challengeName}, ${challengeParameters}`
                )
            },
            totpRequired: (challengeName: any, challengeParameters: any) => {
                reject(
                    `In newPasswordRequired: ${challengeName}, ${challengeParameters}`
                )
            },
            customChallenge: (challengeParameters: any) => {
                reject(`In newPasswordRequired: ${challengeParameters}`)
            },
            mfaSetup: (challengeName: any, challengeParameters: any) => {
                reject(
                    `In newPasswordRequired: ${challengeName}, ${challengeParameters}`
                )
            },
            selectMFAType: (challengeName: any, challengeParameters: any) => {
                reject(
                    `In newPasswordRequired: ${challengeName}, ${challengeParameters}`
                )
            },
        })
    })
}
