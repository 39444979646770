export function addSpacesForCamelCases(originalString: string): string {
    return originalString.replace(/([A-Z])/g, ' $1').trim()
}

export function moneyNumberToString(number: number | null | undefined): string {
    if (!number && number !== 0) {
        return ''
    }

    const numberString = number.toString()
    const decimalIndex = numberString.indexOf('.')
    const wholeDigits =
        decimalIndex >= 0
            ? numberString.substring(0, decimalIndex)
            : numberString

    const formattedWholeDigits = `$ ${wholeDigits}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
    )
    const decimalDigits =
        decimalIndex >= 0 ? numberString.substring(decimalIndex) : ''
    return formattedWholeDigits + decimalDigits
}

export function percentageNumberToString(
    number: number | null | undefined
): string {
    return !number && number != 0 ? '' : `${number} %`
}
