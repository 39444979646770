import {
    AssetType,
    CreateEvent,
    Event,
    ExistingConnection,
    Organization,
    validateCreateEvent,
    IntroductionOrigin,
    PhysicalLocation,
} from '@dsmaccy/echomodel'
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addEvent } from '../../../controllers/event_controller'
import MessageConstants from '../../../message_constants'
import _ from 'lodash'
import { useGetUserInfo } from '../../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../../utils/monitor'
import { validateAndGetUserInfo } from '../../../utils/validation_utils'
import { useNavigate, useParams } from 'react-router'
import {
    loadEvents,
    loadExistingConnections,
    loadOrganizations,
} from '../../../utils/load_entities'
import IntroductionSelect from '../../../molecules/introduction_select'
import {
    moneyNumberToString,
    percentageNumberToString,
} from '../../../utils/data_mapping_utils'
import LocationSelect from '../../../molecules/location_select'
import dayjs from 'dayjs'

async function callAddEvent(
    setAddingEvent: (eventBeingAdded: boolean) => void,
    userInfo: UserInfo,
    createEventDTO: CreateEvent
) {
    setAddingEvent(true)

    try {
        let validatedCreateEvent: CreateEvent
        try {
            validatedCreateEvent = validateCreateEvent(createEventDTO)
        } catch (err) {
            MonitorSingleton.sendException(err)
            toast(MessageConstants.BAD_ADD_EVENT_INPUT, {
                type: 'error',
            })
            console.error(err)
            return
        }

        await addEvent(userInfo.id, validatedCreateEvent)
        toast(MessageConstants.addedEventMessage(createEventDTO.name), {
            type: 'success',
        })
    } catch (err) {
        MonitorSingleton.sendException(err)
        console.error(err)
        toast(MessageConstants.UNABLE_TO_ADD_EVENT, { type: 'error' })
    } finally {
        setAddingEvent(false)
    }
}

const defaultEvent: CreateEvent = {
    name: '',
    date: null,
    location: null,
    organizationId: '',
}

export default function AddEvent() {
    const { organizationId } = useParams()
    const [newEvent, setNewEvent] = useState<CreateEvent>({
        ...defaultEvent,
        organizationId: organizationId ?? '',
    })

    const [addingEvent, setAddingEvent] = useState<boolean>(false)
    const [allOrganizations, setAllOrganizations] = useState<
        Organization[] | undefined
    >()

    const navigate = useNavigate
    const getUserInfo = useGetUserInfo()

    useEffect(() => {
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
    }, [])

    const submit = async () => {
        if (!newEvent) {
            return
        }
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return
        }
        callAddEvent(setAddingEvent, userInfo, newEvent)
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Add New Event</Title>
            </div>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                <Form.Item label="Name" name="name">
                    <Input
                        value={newEvent.name}
                        onChange={(event) =>
                            setNewEvent({
                                ...newEvent,
                                name: event.target.value,
                            })
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Event Location" name="eventLocation">
                    <LocationSelect
                        locationValue={newEvent?.location}
                        onChange={(location: PhysicalLocation | null) => {
                            if (!_.isEqual(location, newEvent?.location)) {
                                setNewEvent({
                                    ...newEvent,
                                    location: location,
                                })
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label="Event Date" name="eventDate">
                    <DatePicker
                        style={{ width: '100%' }}
                        popupStyle={{
                            height: '1px',
                        }}
                        value={dayjs(newEvent.date)}
                        onChange={(date: any, dateString: string) => {
                            setNewEvent({
                                ...newEvent,
                                date: dateString,
                            })
                        }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Hosting Organization" name="organization">
                    <Select
                        allowClear
                        defaultValue={organizationId}
                        value={newEvent.organizationId}
                        onChange={(selectValue: string | null) => {
                            setNewEvent({
                                ...newEvent,
                                organizationId: selectValue ?? '',
                            })
                        }}
                    >
                        {allOrganizations?.map((organization) => (
                            <Select.Option
                                key={organization.entityId}
                                value={organization.entityId}
                            >
                                {organization.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={submit}
                        disabled={addingEvent || !newEvent}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
