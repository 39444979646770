import { Button, Form, Input, Modal } from 'antd'
import { useState } from 'react'
import { Typography } from 'antd'
import Title from 'antd/lib/typography/Title'
import RouteConstants from '../routes_constants'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import MessageConstants from '../message_constants'
import { validatePassword } from '../utils/validation_utils'
import {
    useConfirmPassword,
    useForgotPassword,
} from '../controllers/authentication/clients/cognito'
import { MonitorSingleton } from '../utils/monitor'

const { Text } = Typography

export default function ForgotPassword() {
    const forgotPassword = useForgotPassword()
    const confirmPassword = useConfirmPassword()
    const [username, setUsername] = useState<string>()
    const [message, setMessage] = useState<string | undefined>(undefined)
    const [modalMessage, setModalMessage] = useState<string | undefined>(
        undefined
    )
    const [messageSent, setMessageSent] = useState<boolean>(false)
    const [password, setPassword] = useState<string | undefined>(undefined)
    const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] =
        useState<boolean>(false)
    const [code, setCode] = useState<string | undefined>(undefined)

    async function handleChangePasswordClick(): Promise<void> {
        if (!username || !code || !password) {
            throw new Error('Username, code and password must all be set')
        }

        if (!validatePassword(password)) {
            setModalMessage(MessageConstants.INVALID_PASSWORD)
            return
        }

        try {
            const passwordChangeResult = await confirmPassword(
                username,
                code,
                password
            )

            if (passwordChangeResult === 'SUCCESS') {
                setPasswordChangedSuccessfully(true)
            }
        } catch (err: any) {
            const errorString: string = err.toString()
            if (
                errorString.includes(
                    'CodeMismatchException: Invalid verification code provided, please try again.'
                )
            ) {
                setModalMessage(MessageConstants.LOGIN_ERROR_BAD_CODE)
            } else if (
                errorString.includes(
                    'LimitExceededException: Attempt limit exceeded, please try after some time.'
                )
            ) {
                setModalMessage(MessageConstants.LOGIN_ERROR_TOO_MANY_ATTEMPTS)
            } else {
                MonitorSingleton.sendException(err)
                setModalMessage(MessageConstants.UNRECOGNIZED_ERROR)
                toast(MessageConstants.UNRECOGNIZED_ERROR, { type: 'error' })
            }
        }
    }

    async function handleSubmit(): Promise<void> {
        if (!username) {
            setMessage('Username must be set')
            return
        }
        try {
            const result = await forgotPassword(username)
            const medium: string = result.CodeDeliveryDetails.DeliveryMedium
            const destination: string = result.CodeDeliveryDetails.Destination
            setMessage(undefined)
            setModalMessage(undefined)
            toast(`Code sent to ${medium.toLowerCase()} ${destination}`, {
                type: 'info',
            })
            setMessageSent(true)
        } catch (err: any) {
            setMessageSent(false)
            const errorString = err.toString()
            if (
                errorString.includes(
                    'Cannot reset password for the user as there is no registered/verified email or phone_number'
                )
            ) {
                setMessage(MessageConstants.noContactInfo(username))
            } else if (
                errorString.includes(
                    'LimitExceededException: Attempt limit exceeded, please try after some time.'
                )
            ) {
                setMessage(
                    MessageConstants.unableToGetInfoFromUsername(username)
                )
            } else if (
                errorString.includes(
                    'UserNotFoundException: Username/client id combination not found.'
                )
            ) {
                setMessage(MessageConstants.unknownUsernameMessage(username))
            } else if (
                errorString.includes(
                    'NotAuthorizedException: User password cannot be reset in the current state.'
                )
            ) {
                setMessage(
                    MessageConstants.BAD_USER_STATUS_PREVENTING_PASSWORD_RESET
                )
            } else {
                setMessage(MessageConstants.UNRECOGNIZED_ERROR)
                toast(MessageConstants.UNRECOGNIZED_ERROR, { type: 'error' })
                MonitorSingleton.sendException(err)
            }
        }
    }

    if (passwordChangedSuccessfully) {
        return (
            <div style={{ padding: '20px' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Title level={3}>Forgot Password</Title>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Text>Password Changed Successfully!</Text>
                    <Text>
                        Click <Link to={RouteConstants.LOGIN}>here</Link> to
                        return to login
                    </Text>
                </div>
            </div>
        )
    }

    return (
        <div style={{ padding: '20px' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Forgot Password</Title>
            </div>
            <Form>
                <Form.Item
                    label="Username"
                    name="username"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 8 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input
                        value={username}
                        onChange={(event) => {
                            setUsername(event.target.value)
                            setMessageSent(false)
                        }}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
                    <Button disabled={!username} onClick={handleSubmit}>
                        Submit
                    </Button>
                </Form.Item>
                <Form.Item>
                    {message && <Text type="danger">{message}</Text>}
                </Form.Item>
            </Form>
            <Modal
                title="Forgot Password"
                centered
                closable={false}
                visible={messageSent && !passwordChangedSuccessfully}
                destroyOnClose={true}
                onOk={() => handleChangePasswordClick()}
                okText={'Submit'}
                onCancel={() => setMessageSent(false)}
                maskClosable={false}
            >
                <Form>
                    <Form.Item
                        label="Code"
                        name="code"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username',
                            },
                        ]}
                    >
                        <Input
                            value={code}
                            onChange={(event) => setCode(event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        label="New Password"
                        name="newpassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password',
                            },
                        ]}
                    >
                        <Input.Password
                            value={password}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                        />
                    </Form.Item>
                    <Form.Item>
                        {modalMessage && (
                            <Text type="danger">{modalMessage}</Text>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
