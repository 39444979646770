import {
    CreateOrganization,
    validateCreateOrganization,
    Industry,
    PhysicalLocation,
} from '@dsmaccy/echomodel'
import { Button, Form, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { addOrganization } from '../../controllers/organization_controller'
import MessageConstants from '../../message_constants'
import _ from 'lodash'
import { useGetUserInfo } from '../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'
import { useNavigate } from 'react-router'
import IndustrySelect from '../../molecules/industry_select'
import LocationSelect from '../../molecules/location_select'

async function callAddOrganization(
    setAddingOrganization: (organizationBeingAdded: boolean) => void,
    userInfo: UserInfo,
    createOrganizationDTO: CreateOrganization
) {
    setAddingOrganization(true)

    try {
        let validatedCreateOrganization: CreateOrganization
        try {
            validatedCreateOrganization = validateCreateOrganization(
                createOrganizationDTO
            )
        } catch (err) {
            MonitorSingleton.sendException(err)
            toast(MessageConstants.BAD_ADD_ORGANIZATION_INPUT, {
                type: 'error',
            })
            console.error(err)
            return
        }

        await addOrganization(userInfo.id, validatedCreateOrganization)
        toast(
            MessageConstants.addedOrganizationMessage(
                createOrganizationDTO.name
            ),
            {
                type: 'success',
            }
        )
    } catch (err) {
        MonitorSingleton.sendException(err)
        console.error(err)
        toast(MessageConstants.UNABLE_TO_ADD_ORGANIZATION, { type: 'error' })
    } finally {
        setAddingOrganization(false)
    }
}

const defaultOrganization: CreateOrganization = {
    name: '',
    location: null,
    industry: null,
}

export default function AddOrganization() {
    const [newOrganization, setNewOrganization] =
        useState<CreateOrganization>(defaultOrganization)

    const [addingOrganization, setAddingOrganization] = useState<boolean>(false)

    const navigate = useNavigate
    const getUserInfo = useGetUserInfo()

    const submit = async () => {
        if (!newOrganization) {
            return
        }
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return
        }
        callAddOrganization(setAddingOrganization, userInfo, newOrganization)
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Add New Organization</Title>
            </div>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                <Form.Item label="Name" name="name">
                    <Input
                        value={newOrganization.name}
                        onChange={(event) =>
                            setNewOrganization({
                                ...newOrganization,
                                name: event.target.value,
                            })
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    label="Organization Location"
                    name="organizationLocation"
                >
                    <LocationSelect
                        locationValue={newOrganization?.location}
                        onChange={(location: PhysicalLocation | null) => {
                            if (
                                !_.isEqual(location, newOrganization?.location)
                            ) {
                                setNewOrganization({
                                    ...newOrganization,
                                    location: location,
                                })
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label="Industry" name="industry">
                    <IndustrySelect
                        industryValue={newOrganization.industry}
                        onChange={(industry: Industry | null) => {
                            setNewOrganization({
                                ...newOrganization,
                                industry: industry,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={submit}
                        disabled={addingOrganization || !newOrganization}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
