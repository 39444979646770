import { Card } from 'antd'
import Title from 'antd/lib/typography/Title'
import ConnectionGraph from '../molecules/graphs/d3/connection_graph'
import ReactFlowConnectionGraph from '../molecules/graphs/reactflow/connection_graph'

export default function Home() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Title level={3}>Welcome to Echo</Title>
            <Card bordered={false} style={{ margin: '10px' }}>
                <ReactFlowConnectionGraph />
            </Card>
        </div>
    )
}
