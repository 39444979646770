import { toast } from 'react-toastify'
import MessageConstants from '../message_constants'
import RouteConstants from '../routes_constants'
import { UserInfo } from '../controllers/authentication/UserInfo'

// Password requirements set in cognito:
export const PASSWORD_CONDITIONS =
    'contains at least 1 number, ' +
    'contains at least 1 special character (^$*.[]{}()?-"!@#%&/,><\':;|_~`+=), ' +
    'contains at least 1 uppercase letter, ' +
    'contains at least 1 lowercase letter'

// const passwordRegex =
//     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?\/~_+-=|\]).{8,32}$/
export function validatePassword(password: string): Boolean {
    if (password.length < 8) {
        return false
    }
    // At least one digit
    if (!RegExp(/.*\d.*/).test(password)) {
        return false
    }
    // At least one lowercase letter
    if (!RegExp(/.*[a-z].*/).test(password)) {
        return false
    }
    // at least one uppercase letter
    if (!RegExp(/.*[A-Z].*/).test(password)) {
        return false
    }

    const specialCharacters = '^$*.[]{}()?-"!@#%&/,><\\\':;|_~`+='
    let specialCharacterFound = false
    for (let i = 0; i < specialCharacters.length; i++) {
        if (password.includes(specialCharacters.charAt(i))) {
            specialCharacterFound = true
            break
        }
    }
    if (!specialCharacterFound) {
        return false
    }
    return true
}

export async function validateAndGetUserInfo(
    getUserInfo: () => Promise<UserInfo | undefined>,
    redirect: (route: string) => void
): Promise<UserInfo | undefined> {
    const userInfo = await getUserInfo()
    if (!userInfo) {
        toast(MessageConstants.SESSION_EXPIRED, { type: 'warning' })
        redirect(RouteConstants.LOGIN)
    }
    return userInfo
}
