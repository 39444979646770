import {
    CreateEvent,
    Event,
    validateEvent,
    validateCreateEvent,
} from '@dsmaccy/echomodel/dist'
import CacheConstants from './cache_constants'
import { getAllEntities, getEntity, addEntity, editEntity, deleteEntity } from './entity_controller'

const ENTITY_PATH = "event"
export function getAllEvents(
    userId: string
): Promise<Event[]> {
    return getAllEntities(ENTITY_PATH, 'getAllEvents', CacheConstants.EVENTS, userId)
}

export function getEvent(
    userId: string,
    entityId: string
): Promise<Event | undefined> {
    return getEntity(ENTITY_PATH, 'getEvent', CacheConstants.EVENTS, userId, entityId)
}

export function addEvent(
    userId: string,
    createEvent: CreateEvent
): Promise<string> {
    return addEntity(ENTITY_PATH, 'addEvent', CacheConstants.EVENTS, userId, createEvent, validateCreateEvent, validateEvent)
}

export async function editEvent(
    event: Event
): Promise<void> {
    return editEntity(ENTITY_PATH, 'editEvent', CacheConstants.EVENTS, event)
}

export async function deleteEvent(
    userId: string,
    entityId: string
): Promise<void> {
    return deleteEntity(ENTITY_PATH, 'deleteEvent', CacheConstants.EVENTS, userId, entityId)
}
