import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import {
    ExistingConnection,
    Organization,
    Event,
    IntroductionOrigin,
    IntroductionType,
} from '@dsmaccy/echomodel'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'
import IntroductionSelect from '../introduction_select'

function getDisplayName(
    introductionOriginValue: IntroductionOrigin | null,
    existingConnections?: ExistingConnection[],
    organizations?: Organization[],
    events?: Event[]
) {
    if (!introductionOriginValue) {
        return ''
    }
    switch (introductionOriginValue.type) {
        case IntroductionType.Person:
            const connection =
                existingConnections?.find(
                    (connection) =>
                        connection.entityId === introductionOriginValue.id
                ) ?? null
            return connection
                ? connection.firstName + ' ' + connection.lastName
                : ''

        case IntroductionType.Event:
            const event =
                events?.find(
                    (event) => event.entityId === introductionOriginValue.id
                ) ?? null
            return event?.name ?? ''
        case IntroductionType.Organization:
            const organization =
                organizations?.find(
                    (organization) =>
                        organization.entityId === introductionOriginValue.id
                ) ?? null
            return organization?.name ?? ''
    }
}

export default function TextOrInputIntroductionOriginFieldEdit(props: {
    introductionOrigin: IntroductionOrigin | null
    onCommitChange?: (newValue: IntroductionOrigin | null) => void
    entityIdToOmit?: string | undefined
    existingConnections?: ExistingConnection[]
    organizations?: Organization[]
    events?: Event[]
}) {
    const [introductionOriginTempValue, setIntroductionOriginTempValue] =
        useState<IntroductionOrigin | null>(props.introductionOrigin)
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setIntroductionOriginTempValue(props?.introductionOrigin)
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setIntroductionOriginTempValue(props?.introductionOrigin)
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    let onCommitChange = () => {
        setEditField(false)
        if (props.onCommitChange) {
            if (!introductionOriginTempValue) {
                props.onCommitChange(null)
            } else {
                props.onCommitChange(introductionOriginTempValue)
            }
        }
    }

    if (editField) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                <IntroductionSelect
                    introductionOriginValue={introductionOriginTempValue}
                    entityIdToOmit={props.entityIdToOmit}
                    existingConnections={props.existingConnections}
                    organizations={props.organizations}
                    events={props.events}
                    onChange={setIntroductionOriginTempValue}
                />
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {getDisplayName(
                    props?.introductionOrigin,
                    props?.existingConnections,
                    props?.organizations,
                    props?.events
                )}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
