import { Popconfirm, Space, Table } from 'antd'
import {
    deleteOrganization,
    getAllOrganizations,
} from '../../controllers/organization_controller'
import RouteConstants from '../../routes_constants'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    DeleteOutlined,
    PlusCircleOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { toast } from 'react-toastify'
import Loading from '../../molecules/loading'
import Column from 'antd/lib/table/Column'
import Text from 'antd/lib/typography/Text'
import MessageConstants from '../../message_constants'
import FilterDropdown from '../../molecules/filter_dropdown'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import Title from 'antd/lib/typography/Title'
import { useGetUserInfo } from '../../controllers/authentication/clients/auth0'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'
import { Industry, PhysicalLocation } from '@dsmaccy/echomodel'

type DataSourceObject = {
    key: string
    name: string
    location: PhysicalLocation | null
    industry: Industry | null
}

export default function ViewAllOrganizations() {
    const getUserInfo = useGetUserInfo()
    const [tableData, setTableData] = useState<DataSourceObject[]>([])
    const [doneLoading, setDoneLoading] = useState<boolean>(false)
    const [selectedRows, setSelectedRows] = useState<
        DataSourceObject[] | undefined
    >(undefined)
    const [_searchText, setSearchText] = useState<string | undefined>()
    const [_searchedColumn, setSearchedColumn] = useState<string | undefined>()
    const [confirmDeleting, setConfirmDeleting] = useState(false)
    const navigate = useNavigate()

    const loadUserData = async () => {
        try {
            const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
            if (!userInfo) {
                return
            }

            const organizations = await getAllOrganizations(userInfo.id)

            setTableData(
                organizations.map((organization) => {
                    return {
                        key: organization.entityId,
                        name: organization.name,
                        location: organization.location,
                        industry: organization.industry,
                    }
                })
            )
        } catch (err) {
            MonitorSingleton.sendException(err)
            toast(MessageConstants.UNABLE_TO_LOAD_ORGANIZATIONS, {
                type: 'error',
            })
        } finally {
            setDoneLoading(true)
        }
    }

    useEffect(() => {
        loadUserData()
    }, [])

    let onSelectedRowChange = (
        selectedRowKeys: React.Key[],
        selectedRows: DataSourceObject[]
    ) => {
        if (selectedRows.length === 0) {
            setSelectedRows(undefined)
        } else {
            setSelectedRows(selectedRows)
        }
    }

    let handleSearch = (
        selectedKeys: React.Key[],
        confirm: (param?: FilterConfirmProps | undefined) => void,
        dataIndex: string
    ) => {
        confirm()
        setSearchText(selectedKeys[0]?.toString())
        setSearchedColumn(dataIndex)
    }

    let handleReset = (clearFilters: (() => void) | undefined) => {
        if (clearFilters) {
            clearFilters()
        }
        setSearchText('')
    }

    let filteredIconFunction = (filtered: boolean) => (
        <SearchOutlined
            style={{
                color: filtered ? '#1890ff' : undefined,
            }}
        />
    )

    let handleDeleteClick = async () => {
        setConfirmDeleting(true)
        try {
            const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)

            if (!userInfo || !selectedRows || selectedRows.length <= 0) {
                return
            }
            await Promise.all(
                selectedRows.map((item: DataSourceObject) => {
                    return deleteOrganization(userInfo?.id, item.key)
                })
            )
            toast('Organizations deleted', {
                type: 'success',
            })
            setSelectedRows(undefined)
            loadUserData()
        } catch (e) {
            toast('Error occurred when trying to delete organization', {
                type: 'error',
            })
        } finally {
            setConfirmDeleting(false)
        }
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Organizations</Title>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    margin: '15px',
                }}
            >
                <Link to={RouteConstants.ADD_ORGANIZATION}>
                    <PlusCircleOutlined
                        style={{ fontSize: '25px', paddingLeft: '10px' }}
                    />
                </Link>
                {selectedRows && selectedRows.length > 0 && (
                    <Popconfirm
                        title={
                            'Delete selected organization' +
                            (selectedRows.length > 1 ? 's' : '') +
                            '?'
                        }
                        okText={'Delete'}
                        okButtonProps={{ loading: confirmDeleting }}
                        okType="danger"
                        cancelButtonProps={{ disabled: confirmDeleting }}
                        onConfirm={handleDeleteClick}
                    >
                        <DeleteOutlined
                            style={{
                                fontSize: '25px',
                                paddingLeft: '10px',
                                color: 'red',
                            }}
                        />
                    </Popconfirm>
                )}
            </div>
            {
                !doneLoading && <Loading />
                /* TODO: Consider not having a separate loading (data will just naturally be empty?)  --> Maybe use a default (... data type?) */
            }
            {doneLoading && (
                <Table
                    showHeader
                    rowSelection={{
                        preserveSelectedRowKeys: false,
                        type: 'checkbox',
                        onChange: onSelectedRowChange,
                    }}
                    dataSource={tableData}
                >
                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                        sorter={(a: DataSourceObject, b: DataSourceObject) =>
                            a.name.localeCompare(b.name)
                        }
                        width="50%"
                        ellipsis
                        filterDropdown={({
                            setSelectedKeys,
                            selectedKeys,
                            confirm,
                            clearFilters,
                        }) => {
                            return (
                                <FilterDropdown
                                    setSelectedKeys={setSelectedKeys}
                                    selectedKeys={selectedKeys}
                                    confirm={confirm}
                                    clearFilters={clearFilters}
                                    dataColumnLabel="name"
                                    handleReset={handleReset}
                                    handleSearch={handleSearch}
                                />
                            )
                        }}
                        onFilter={(
                            value: string | number | boolean,
                            record: any
                        ) =>
                            record['name']
                                ? record['name']
                                      .toString()
                                      .toLowerCase()
                                      .includes(value.toString().toLowerCase())
                                : ''
                        }
                        filterIcon={filteredIconFunction}
                    />
                    <Column
                        title="Action"
                        key="action"
                        width="30%"
                        render={(text, record: any) => (
                            <Space size="middle">
                                <Text>
                                    <Link
                                        to={RouteConstants.viewOrganizationRoute(
                                            record.key
                                        )}
                                    >
                                        View Details and Events
                                    </Link>
                                </Text>
                            </Space>
                        )}
                    />
                </Table>
            )}
        </div>
    )
}
