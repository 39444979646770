import { Menu } from 'antd'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../routes_constants'
import LoggedInTemplate from './logged_in_template'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

export interface SettingsProps {
    selectedKey: 'User' | 'ChangePassword'
    children: any
}

export default function Settings(props: SettingsProps) {
    const navigate = useNavigate()
    const menuItems: ItemType[] = [
        {
            key: 'User',
            label: 'User',
            title: 'User',
            onClick: () => {
                navigate(RouteConstants.USER_SETTINGS)
            },
        },
    ]
    return (
        <LoggedInTemplate>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    style={{
                        height: '100%',
                        width: '150px',
                    }}
                    hidden={
                        true
                        /*
                         * NOTE: There's only one type of setting, so this is a bit excessive.
                         * Consider making visible if more settings are created.
                         * If made visible, may need to consider improving UI/making more pretty
                         */
                    }
                    items={menuItems}
                    selectedKeys={[props.selectedKey]}
                />
                <div style={{ width: '100%' }}>{props?.children}</div>
            </div>
        </LoggedInTemplate>
    )
}
