import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../molecules/loading'
import RouteConstants from '../routes_constants'
import Text from 'antd/lib/typography/Text'
import { toast } from 'react-toastify'
import MessageConstants from '../message_constants'
import { useGetSessionState } from '../controllers/authentication/clients/auth0'
import { SessionState } from '../controllers/authentication/SessionState'
import { MonitorSingleton } from '../utils/monitor'

export default function AuthenticationWrapper(props: any): JSX.Element {
    const getSessionState = useGetSessionState()
    let [sessionState, setSessionState] = useState<SessionState>(
        SessionState.Unknown
    )
    const [isLoadingSessionState, setIsLoadingSessionState] = useState(true)

    let navigate = useNavigate()

    async function loginState() {
        try {
            let state = await getSessionState()
            if (state === SessionState.LoggedOut) {
                navigate(RouteConstants.LOGIN, { replace: false })
            } else {
                setSessionState(state)
                setIsLoadingSessionState(false)
            }
        } catch (err) {
            toast(MessageConstants.UNABLE_TO_VALIDATE_LOGIN, { type: 'error' })
            MonitorSingleton.sendException(err)
            setIsLoadingSessionState(false)
        }
    }

    useEffect(() => {
        loginState()
    }, [])

    if (sessionState === SessionState.Unknown) {
        if (isLoadingSessionState) {
            return <Loading />
        } else {
            return <Text>{MessageConstants.UNABLE_TO_VALIDATE_LOGIN}</Text>
        }
    } else if (sessionState === SessionState.LoggedOut) {
        return (
            <Text>
                No user logged in. Redirecting to{' '}
                <Link to={RouteConstants.LOGIN}>login page</Link>...
            </Text>
        )
    } else {
        return props?.children
    }
}
