import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import 'antd/dist/antd.less'
import { MonitorSingleton } from './utils/monitor'
import { createRoot } from 'react-dom/client';

MonitorSingleton.initialize()

// amplify.Auth.configure({
//     identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
//     region: process.env.REACT_APP_COGNITO_POOL_REGION,
//     userPoolId: process.env.REACT_APP_COGNITO_POOL_USER_POOL_ID,

//     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//     userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,

//     // TODO: Should this be enforced?
//     // mandatorySignIn: false,
// })
// Amplify.configure(... stuff in aws-exports)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
