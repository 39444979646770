import {
    ExistingConnection,
    Industry,
    IntroductionOrigin,
    Organization,
    validateExistingConnection,
    Event,
} from '@dsmaccy/echomodel'
import { Col, Row, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    editExistingConnection,
    getExistingConnection,
} from '../../controllers/existing_connection_controller'
import MessageConstants from '../../message_constants'
import TextOrInputFieldEdit from '../../molecules/text_input_wrappers/text_or_input_field_edit'
import TextOrInputNumberFieldEdit from '../../molecules/text_input_wrappers/text_or_input_number_field_edit'
import TextOrInputDateFieldEdit from '../../molecules/text_input_wrappers/text_or_input_date_field_edit'
import Loading from '../../molecules/loading'
import { isEqual } from 'lodash-es'
import { PhysicalLocation } from '@dsmaccy/echomodel'
import TextOrInputLocationFieldEdit from '../../molecules/text_input_wrappers/text_or_input_location_field_edit'
import TextOrInputIndustryFieldEdit from '../../molecules/text_input_wrappers/text_or_input_industry_field_edit'
import TextOrInputIntroductionOriginFieldEdit from '../../molecules/text_input_wrappers/text_or_input_introduction_origin_field_edit'
import { useGetUserInfo } from '../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'
import {
    loadEvents,
    loadExistingConnections,
    loadOrganizations,
} from '../../utils/load_entities'

async function updateConnection(
    newConnection: ExistingConnection | undefined,
    originalConnection: ExistingConnection | undefined,
    setOriginalConnection: (connection: ExistingConnection) => void
) {
    try {
        if (
            !newConnection ||
            !originalConnection ||
            isEqual(newConnection, originalConnection)
        ) {
            return
        }

        // Validate input (TODO: Consider moving any additional mapping to model?)
        let existingConnection = newConnection
        if (
            !newConnection.primaryLocation?.country &&
            !newConnection.primaryLocation?.state &&
            !newConnection.primaryLocation?.city
        ) {
            existingConnection.primaryLocation = null
        }
        existingConnection = validateExistingConnection(existingConnection)

        await editExistingConnection(existingConnection)
        setOriginalConnection(existingConnection)
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.FAILED_TO_EDIT_CONNECTION, { type: 'error' })
    }
}

let loadSingleExistingConnection = async (
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void,
    entityId: string
): Promise<ExistingConnection | undefined> => {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return undefined
        }
        const existingConnection = await getExistingConnection(
            userInfo.id,
            entityId
        )
        if (!existingConnection) {
            throw new Error(MessageConstants.UNABLE_TO_GET_CONNECTION_INFO)
        }
        return existingConnection
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.UNABLE_TO_GET_CONNECTION_INFO, {
            type: 'error',
        })
    }
}

export default function ViewExistingConnection() {
    const getUserInfo = useGetUserInfo()
    const { entityId } = useParams()

    const [currentConnection, setCurrentConnection] = useState<
        ExistingConnection | undefined
    >()
    // const [userId, setUserId] = useState<string | undefined>(undefined)
    const [startingConnection, setOriginalConnection] =
        useState<ExistingConnection>()

    const [doneLoading, setDoneLoading] = useState<boolean>(false)
    const [allConnections, setAllExistingConnections] = useState<
        ExistingConnection[] | undefined
    >()
    const [allEvents, setAllEvents] = useState<Event[] | undefined>()
    const [allOrganizations, setAllOrganizations] = useState<
        Organization[] | undefined
    >()

    const navigate = useNavigate()

    useEffect(() => {
        if (!entityId) {
            return
        }
        loadSingleExistingConnection(getUserInfo, navigate, entityId).then(
            (existingConnection) => {
                if (existingConnection) {
                    setOriginalConnection(existingConnection)
                    setCurrentConnection(existingConnection)
                    setDoneLoading(true)
                }
            }
        )
        loadExistingConnections(getUserInfo, navigate).then(
            (existingConnections: ExistingConnection[] | undefined) => {
                setAllExistingConnections(existingConnections)
            }
        )
        loadEvents(getUserInfo, navigate).then(
            (events: Event[] | undefined) => {
                setAllEvents(events)
            }
        )
        loadOrganizations(getUserInfo, navigate).then(
            (organizations: Organization[] | undefined) => {
                setAllOrganizations(organizations)
            }
        )
    }, [])

    if (!entityId) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Person {entityId} not found</Title>
            </div>
        )
    }

    if (!doneLoading) {
        return <Loading />
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>
                    Viewing Connection {startingConnection?.firstName}{' '}
                    {startingConnection?.lastName}
                </Title>
            </div>
            <Space
                direction="vertical"
                size="large"
                style={{ display: 'flex' }}
            >
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>First Name:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputFieldEdit
                            stringValue={currentConnection?.firstName}
                            onCommitChange={async (newFirstName) => {
                                setCurrentConnection({
                                    ...currentConnection,
                                    firstName: newFirstName,
                                } as ExistingConnection)
                                updateConnection(
                                    {
                                        ...startingConnection,
                                        firstName: newFirstName,
                                    } as ExistingConnection,
                                    startingConnection,
                                    setOriginalConnection
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Last Name:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputFieldEdit
                            stringValue={currentConnection?.lastName}
                            onCommitChange={async (newLastName) => {
                                setCurrentConnection({
                                    ...currentConnection,
                                    lastName: newLastName,
                                } as ExistingConnection)
                                updateConnection(
                                    {
                                        ...startingConnection,
                                        lastName: newLastName,
                                    } as ExistingConnection,
                                    startingConnection,
                                    setOriginalConnection
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Industry:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputIndustryFieldEdit
                            industryValue={currentConnection?.industry}
                            onCommitChange={async (
                                industry: Industry | null
                            ) => {
                                setCurrentConnection({
                                    ...currentConnection,
                                    industry: industry,
                                } as ExistingConnection)
                                updateConnection(
                                    {
                                        ...startingConnection,
                                        industry: industry,
                                    } as ExistingConnection,
                                    startingConnection,
                                    setOriginalConnection
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>

                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Birthday:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputDateFieldEdit
                            isoDateValue={currentConnection?.birthday}
                            onCommitChange={async (newBirthday) => {
                                setCurrentConnection({
                                    ...currentConnection,
                                    birthday: newBirthday,
                                } as ExistingConnection)
                                updateConnection(
                                    {
                                        ...startingConnection,
                                        birthday: newBirthday,
                                    } as ExistingConnection,
                                    startingConnection,
                                    setOriginalConnection
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Estimated Net Worth:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputNumberFieldEdit
                            numberValue={currentConnection?.estimatedNetWorth}
                            money
                            onCommitChange={async (newEstimatedNetWorth) => {
                                setCurrentConnection({
                                    ...currentConnection,
                                    estimatedNetWorth: newEstimatedNetWorth,
                                } as ExistingConnection)
                                updateConnection(
                                    {
                                        ...startingConnection,
                                        estimatedNetWorth: newEstimatedNetWorth,
                                    } as ExistingConnection,
                                    startingConnection,
                                    setOriginalConnection
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Primary Location:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputLocationFieldEdit
                            locationValue={currentConnection?.primaryLocation}
                            onCommitChange={async (
                                location: PhysicalLocation | null
                            ) => {
                                setCurrentConnection({
                                    ...currentConnection,
                                    primaryLocation: location,
                                } as ExistingConnection)
                                updateConnection(
                                    {
                                        ...startingConnection,
                                        primaryLocation: location,
                                    } as ExistingConnection,
                                    startingConnection,
                                    setOriginalConnection
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>How Did We Meet:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputIntroductionOriginFieldEdit
                            introductionOrigin={
                                currentConnection?.introductionOrigin ?? null
                            }
                            entityIdToOmit={currentConnection?.entityId}
                            existingConnections={allConnections}
                            events={allEvents}
                            organizations={allOrganizations}
                            onCommitChange={async (
                                newIntroductionOrigin: IntroductionOrigin | null
                            ) => {
                                if (
                                    !startingConnection ||
                                    startingConnection.introductionOrigin
                                        ?.id === newIntroductionOrigin?.id
                                ) {
                                    return
                                }

                                setCurrentConnection({
                                    ...currentConnection,
                                    introductionOrigin: newIntroductionOrigin,
                                } as ExistingConnection)
                                await updateConnection(
                                    {
                                        ...startingConnection,
                                        introductionOrigin:
                                            newIntroductionOrigin,
                                    },
                                    startingConnection,
                                    setOriginalConnection
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
            </Space>
        </div>
    )
}
