import MessageConstants from '../message_constants'
import { getSessionToken } from './authentication/clients/auth0'
import EntityCache from './entity_cache'
import { MonitorSingleton } from '../utils/monitor'
import Entity from './Entity'

export async function getAllEntities<EntityType>(
    entityPath: string,
    monitoringIdentifier: string,
    cacheName: string,
    userId: string
): Promise<EntityType[]> {
    return await MonitorSingleton.wrapTransaction(
        monitoringIdentifier,
        async (transaction) => {
            const sessionToken = await getSessionToken()
            if (!sessionToken) {
                throw new Error(MessageConstants.BAD_SESSION)
            }
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${sessionToken}`,
                },
            }
            const entitiesInCache = EntityCache.getAllEntities(
                cacheName
            ) as EntityType[]
            if (entitiesInCache) {
                transaction?.setTag('usedCache', true)
                return entitiesInCache
            }
            const response = await fetch(
                process.env.REACT_APP_ECHO_API_GATEWAY_URL +
                    `/${entityPath}/${userId}`,
                requestOptions
            )

            if (response.status >= 400) {
                throw new Error(`Error status ${response.status} received`)
            }
            const entities = await response.json()
            EntityCache.setAllEntities(
                entities,
                cacheName
            )
            return entities
        }
    )
}

export async function getEntity<EntityType>(
    entityPath: string,
    monitoringIdentifier: string,
    cacheName: string,
    userId: string,
    entityId: string
): Promise<EntityType | undefined> {
    return await MonitorSingleton.wrapTransaction(
        monitoringIdentifier,
        async (transaction) => {
            const entityFromCache = EntityCache.getSingleEntity(
                userId,
                entityId,
                cacheName
            ) as Entity
            if (entityFromCache) {
                transaction?.setTag('usedCache', true)
                return entityFromCache
            }
            const sessionToken = await getSessionToken()
            if (!sessionToken) {
                throw new Error(MessageConstants.BAD_SESSION)
            }
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${sessionToken}`,
                },
            }
            const response = await fetch(
                process.env.REACT_APP_ECHO_API_GATEWAY_URL +
                    `/${entityPath}/${userId}/${entityId}`,
                requestOptions
            )

            if (response.status >= 400) {
                throw new Error(`Error status ${response.status} received`)
            }

            const entityFromResponse = await response.json()

            EntityCache.setSingleEntity(
                entityFromResponse,
                cacheName
            )
            return entityFromResponse
        }
    )
}

export async function addEntity<CreateEntityType>(
    entityPath: string,
    monitoringIdentifier: string,
    cacheName: string,
    userId: string,
    createEntity: CreateEntityType,
    validateCreateEntityFunction: (obj: any) => CreateEntityType,
    validateEntityFunction: (obj: any) => Entity
): Promise<string> {
    return await MonitorSingleton.wrapTransaction(
        monitoringIdentifier,
        async (_transaction) => {
            createEntity = validateCreateEntityFunction(
                createEntity
            )
            const sessionToken = await getSessionToken()
            if (!sessionToken) {
                throw new Error(MessageConstants.BAD_SESSION)
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${sessionToken}`,
                },
                body: JSON.stringify(createEntity),
            }
            const response = await fetch(
                process.env.REACT_APP_ECHO_API_GATEWAY_URL +
                    `/${entityPath}/${userId}`,
                requestOptions
            )
            if (response.status >= 400) {
                throw new Error(`Error status ${response.status} received`)
            }
            const newEntityId = await response.text()
            EntityCache.addEntity(
                userId,
                newEntityId,
                createEntity,
                cacheName,
                validateEntityFunction
            )

            return newEntityId
        }
    )
}

export async function editEntity(
    entityPath: string,
    monitoringIdentifier: string,
    cacheName: string,
    entity: Entity
): Promise<void> {
    MonitorSingleton.wrapTransaction(
        monitoringIdentifier,
        async (_transaction) => {
            const sessionToken = await getSessionToken()
            if (!sessionToken) {
                throw new Error(MessageConstants.BAD_SESSION)
            }
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${sessionToken}`,
                },
                body: JSON.stringify(entity),
            }
            const response = await fetch(
                process.env.REACT_APP_ECHO_API_GATEWAY_URL +
                    `/${entityPath}`,
                requestOptions
            )
            if (response.status >= 400) {
                throw new Error(`Error status ${response.status} received`)
            }

            await response.text()
            EntityCache.editEntity(
                entity,
                cacheName
            )
        }
    )
}

export async function deleteEntity(
    entityPath: string,
    monitoringIdentifier: string,
    cacheName: string,
    userId: string,
    entityId: string
): Promise<void> {
    MonitorSingleton.wrapTransaction(
        monitoringIdentifier,
        async (_transaction) => {
            const sessionToken = await getSessionToken()
            if (!sessionToken) {
                throw new Error(MessageConstants.BAD_SESSION)
            }
            const requestOptions = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${sessionToken}`,
                },
            }
            const response = await fetch(
                process.env.REACT_APP_ECHO_API_GATEWAY_URL +
                    `/${entityPath}/${userId}/${entityId}`,
                requestOptions
            )
            if (response.status >= 400) {
                throw new Error(`Error status ${response.status} received`)
            }

            await response.text()
            EntityCache.deleteEntity(
                userId,
                entityId,
                cacheName
            )
        }
    )
}
