import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import { InputNumber } from 'antd'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'
import {
    moneyNumberToString,
    percentageNumberToString,
} from '../../utils/data_mapping_utils'

export default function TextOrInputNumberFieldEdit(props: {
    numberValue?: number | undefined | null
    money?: Boolean
    percentage?: Boolean
    onCommitChange?: (newValue: string | number) => void
}) {
    const [numberTempValue, setNumberTempValue] = useState<
        number | undefined | null
    >(props.numberValue)
    const [editField, setEditField] = useState<Boolean>()

    useEffect(() => {
        setNumberTempValue(props?.numberValue)
    }, [props])

    // Handle escape key press
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === 'Escape') {
                setNumberTempValue(props?.numberValue)
                setEditField(false)
            }
        }

        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    let onNumberChange = (newNumber: number | null) => {
        setNumberTempValue(newNumber)
    }
    let onCommitChange = () => {
        setEditField(false)
        if (!numberTempValue) {
            setNumberTempValue(props?.numberValue)
        } else {
            if (props.onCommitChange) {
                props.onCommitChange(numberTempValue)
            }
        }
    }
    let keyPress = (event: any) => {
        if (event.key === 'Enter') {
            onCommitChange()
        }
    }
    let display = (numberValue: number | null | undefined) => {
        if (props.money) {
            return moneyNumberToString(numberValue)
        } else if (props.percentage) {
            return percentageNumberToString(numberValue)
        }
        return numberValue ? numberValue.toString() : ''
    }

    if (editField) {
        let inputField = (
            <InputNumber
                value={numberTempValue ?? 0}
                onChange={onNumberChange}
                onKeyPress={keyPress}
                style={{ width: '100%' }}
                formatter={(value) => display(value)}
            />
        )
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                }}
            >
                {inputField}
                <CheckCircleTwoTone onClick={onCommitChange} />
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '32px', // NOTE: This is hard-coded based on the Input size.  This may not translate across devices
            }}
        >
            <Text
                style={{
                    width: '100%',
                }}
            >
                {display(props.numberValue)}
            </Text>
            <EditTwoTone
                onClick={() => {
                    setEditField(true)
                }}
            />
        </div>
    )
}
