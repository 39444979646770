import {
    Organization,
    validateOrganization,
    Event,
    PhysicalLocation,
    Industry,
} from '@dsmaccy/echomodel'
import { Col, Popconfirm, Row, Space, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    editOrganization,
    getOrganization,
} from '../../controllers/organization_controller'
import MessageConstants from '../../message_constants'
import TextOrInputFieldEdit from '../../molecules/text_input_wrappers/text_or_input_field_edit'
import Loading from '../../molecules/loading'
import { isEqual } from 'lodash-es'
import { useGetUserInfo } from '../../controllers/authentication/clients/auth0'
import { UserInfo } from '../../controllers/authentication/UserInfo'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'

import TextOrInputLocationFieldEdit from '../../molecules/text_input_wrappers/text_or_input_location_field_edit'
import TextOrInputIndustryFieldEdit from '../../molecules/text_input_wrappers/text_or_input_industry_field_edit'
import Column from 'antd/es/table/Column'
import RouteConstants from '../../routes_constants'
import FilterDropdown from '../../molecules/filter_dropdown'
import { FilterConfirmProps } from 'antd/es/table/interface'
import {
    DeleteOutlined,
    PlusCircleOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { deleteEvent, getAllEvents } from '../../controllers/event_controller'

async function updateOrganization(
    newOrganization: Organization | undefined,
    oldOrganization: Organization | undefined,
    setOrganization: (organization: Organization) => void
) {
    try {
        if (
            !newOrganization ||
            !oldOrganization ||
            isEqual(newOrganization, oldOrganization)
        ) {
            return
        }

        const organization = validateOrganization(newOrganization)

        await editOrganization(organization)
        setOrganization(organization)
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.FAILED_TO_EDIT_ORGANIZATION, { type: 'error' })
    }
}

let loadSingleOrganization = async (
    getUserInfo: () => Promise<UserInfo | undefined>,
    navigate: (route: string) => void,
    entityId: string
): Promise<Organization | undefined> => {
    try {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        if (!userInfo) {
            return undefined
        }
        const organization = await getOrganization(userInfo.id, entityId)
        if (!organization) {
            throw new Error(MessageConstants.UNABLE_TO_GET_ORGANIZATION_INFO)
        }
        return organization
    } catch (err) {
        MonitorSingleton.sendException(err)
        toast(MessageConstants.UNABLE_TO_GET_ORGANIZATION_INFO, {
            type: 'error',
        })
    }
}

type EventDataSourceObject = {
    key: string
    name: string
    date: string | null
    location: PhysicalLocation | null
}

export default function ViewOrganization() {
    const getUserInfo = useGetUserInfo()
    const { entityId } = useParams()

    const [currentOrganization, setCurrentOrganization] = useState<
        Organization | undefined
    >()
    // const [userId, setUserId] = useState<string | undefined>(undefined)
    const [startingOrganization, setStartingOrganization] =
        useState<Organization>()

    const [doneLoading, setDoneLoading] = useState<boolean>(false)

    // Event Stuff
    const [tableData, setTableData] = useState<EventDataSourceObject[]>([])
    const [selectedRows, setSelectedRows] = useState<
        EventDataSourceObject[] | undefined
    >(undefined)
    const [_searchText, setSearchText] = useState<string | undefined>()
    const [_searchedColumn, setSearchedColumn] = useState<string | undefined>()
    const [confirmDeleting, setConfirmDeleting] = useState(false)

    const navigate = useNavigate()

    const loadEvents = async () => {
        try {
            const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
            if (!userInfo) {
                return
            }

            const events = await getAllEvents(userInfo.id)

            setTableData(
                events
                    .filter((event) => event.organizationId === entityId)
                    .map((event) => {
                        return {
                            key: event.entityId,
                            name: event.name,
                            date: event.date,
                            location: event.location,
                        }
                    })
            )
        } catch (err) {
            MonitorSingleton.sendException(err)
            toast(MessageConstants.UNABLE_TO_LOAD_ORGANIZATIONS, {
                type: 'error',
            })
        } finally {
            setDoneLoading(true)
        }
    }

    useEffect(() => {
        if (!entityId) {
            return
        }
        loadSingleOrganization(getUserInfo, navigate, entityId).then(
            (organization) => {
                if (organization) {
                    setStartingOrganization(organization)
                    setCurrentOrganization(organization)
                    setDoneLoading(true)
                }
            }
        )
        loadEvents()
    }, [])

    if (!entityId) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Organization {entityId} not found</Title>
            </div>
        )
    }

    if (!doneLoading) {
        return <Loading />
    }

    let onSelectedRowChange = (
        selectedRowKeys: React.Key[],
        selectedRows: EventDataSourceObject[]
    ) => {
        if (selectedRows.length === 0) {
            setSelectedRows(undefined)
        } else {
            setSelectedRows(selectedRows)
        }
    }

    let handleSearch = (
        selectedKeys: React.Key[],
        confirm: (param?: FilterConfirmProps | undefined) => void,
        dataIndex: string
    ) => {
        confirm()
        setSearchText(selectedKeys[0]?.toString())
        setSearchedColumn(dataIndex)
    }

    let handleReset = (clearFilters: (() => void) | undefined) => {
        if (clearFilters) {
            clearFilters()
        }
        setSearchText('')
    }

    let filteredIconFunction = (filtered: boolean) => (
        <SearchOutlined
            style={{
                color: filtered ? '#1890ff' : undefined,
            }}
        />
    )

    let handleDeleteClick = async () => {
        setConfirmDeleting(true)
        try {
            const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)

            if (!userInfo || !selectedRows || selectedRows.length <= 0) {
                return
            }
            await Promise.all(
                selectedRows.map((item: EventDataSourceObject) => {
                    return deleteEvent(userInfo?.id, item.key)
                })
            )
            toast('Organizations deleted', {
                type: 'success',
            })
            setSelectedRows(undefined)
            loadEvents()
        } catch (e) {
            toast('Error occurred when trying to delete organization', {
                type: 'error',
            })
        } finally {
            setConfirmDeleting(false)
        }
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>
                    Viewing Organization {startingOrganization?.name}
                </Title>
            </div>
            <Space
                direction="vertical"
                size="large"
                style={{ display: 'flex' }}
            >
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Name:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputFieldEdit
                            stringValue={currentOrganization?.name}
                            onCommitChange={async (name) => {
                                setCurrentOrganization({
                                    ...currentOrganization,
                                    name: name,
                                } as Organization)
                                updateOrganization(
                                    {
                                        ...startingOrganization,
                                        name: name,
                                    } as Organization,
                                    startingOrganization,
                                    setStartingOrganization
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Location:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputLocationFieldEdit
                            locationValue={currentOrganization?.location}
                            onCommitChange={async (
                                locationValue: PhysicalLocation | null
                            ) => {
                                setCurrentOrganization({
                                    ...currentOrganization,
                                    location: locationValue,
                                } as Organization)
                                updateOrganization(
                                    {
                                        ...startingOrganization,
                                        locationValue: locationValue,
                                    } as Organization,
                                    startingOrganization,
                                    setStartingOrganization
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row
                    justify="end"
                    style={{ width: '100%' }}
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                >
                    <Col>
                        <Text>Industry:</Text>
                    </Col>
                    <Col span={8}>
                        <TextOrInputIndustryFieldEdit
                            industryValue={currentOrganization?.industry}
                            onCommitChange={async (
                                industryValue: Industry | null
                            ) => {
                                setCurrentOrganization({
                                    ...currentOrganization,
                                    industry: industryValue,
                                } as Organization)
                                updateOrganization(
                                    {
                                        ...startingOrganization,
                                        industry: industryValue,
                                    } as Organization,
                                    startingOrganization,
                                    setStartingOrganization
                                )
                            }}
                        />
                    </Col>
                    <Col span={8} />
                </Row>
                <Row>
                    <Col span={24}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Title level={3}>Organizations</Title>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                alignItems: 'center',
                                margin: '15px',
                            }}
                        >
                            <Link
                                to={RouteConstants.addEventRoute(
                                    currentOrganization?.entityId ?? ''
                                )}
                            >
                                <PlusCircleOutlined
                                    style={{
                                        fontSize: '25px',
                                        paddingLeft: '10px',
                                    }}
                                />
                            </Link>
                            {selectedRows && selectedRows.length > 0 && (
                                <Popconfirm
                                    title={
                                        'Delete selected organization' +
                                        (selectedRows.length > 1 ? 's' : '') +
                                        '?'
                                    }
                                    okText={'Delete'}
                                    okButtonProps={{ loading: confirmDeleting }}
                                    okType="danger"
                                    cancelButtonProps={{
                                        disabled: confirmDeleting,
                                    }}
                                    onConfirm={handleDeleteClick}
                                >
                                    <DeleteOutlined
                                        style={{
                                            fontSize: '25px',
                                            paddingLeft: '10px',
                                            color: 'red',
                                        }}
                                    />
                                </Popconfirm>
                            )}
                        </div>
                        {
                            !doneLoading && <Loading />
                            /* TODO: Consider not having a separate loading (data will just naturally be empty?)  --> Maybe use a default (... data type?) */
                        }
                        {doneLoading && (
                            <Table
                                showHeader
                                rowSelection={{
                                    preserveSelectedRowKeys: false,
                                    type: 'checkbox',
                                    onChange: onSelectedRowChange,
                                }}
                                dataSource={tableData}
                            >
                                <Column
                                    title="Name"
                                    dataIndex="name"
                                    key="name"
                                    sorter={(
                                        a: EventDataSourceObject,
                                        b: EventDataSourceObject
                                    ) => a.name.localeCompare(b.name)}
                                    width="50%"
                                    ellipsis
                                    filterDropdown={({
                                        setSelectedKeys,
                                        selectedKeys,
                                        confirm,
                                        clearFilters,
                                    }) => {
                                        return (
                                            <FilterDropdown
                                                setSelectedKeys={
                                                    setSelectedKeys
                                                }
                                                selectedKeys={selectedKeys}
                                                confirm={confirm}
                                                clearFilters={clearFilters}
                                                dataColumnLabel="name"
                                                handleReset={handleReset}
                                                handleSearch={handleSearch}
                                            />
                                        )
                                    }}
                                    onFilter={(
                                        value: string | number | boolean,
                                        record: any
                                    ) =>
                                        record['name']
                                            ? record['name']
                                                  .toString()
                                                  .toLowerCase()
                                                  .includes(
                                                      value
                                                          .toString()
                                                          .toLowerCase()
                                                  )
                                            : ''
                                    }
                                    filterIcon={filteredIconFunction}
                                />
                                <Column
                                    title="Action"
                                    key="action"
                                    width="30%"
                                    render={(text, record: any) => (
                                        <Space size="middle">
                                            <Text>
                                                <Link
                                                    to={RouteConstants.viewEventRoute(
                                                        record.key
                                                    )}
                                                >
                                                    View Details
                                                </Link>
                                            </Text>
                                        </Space>
                                    )}
                                />
                            </Table>
                        )}
                    </Col>
                </Row>
            </Space>
        </div>
    )
}
