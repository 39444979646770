import { Row, Col, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import MessageConstants from '../../message_constants'
import TextOrInputFieldEdit from '../../molecules/text_input_wrappers/text_or_input_field_edit'
import Loading from '../../molecules/loading'
import {
    useGetUserInfo,
    // useUpdateUserAttributes,
} from '../../controllers/authentication/clients/auth0'
import { MonitorSingleton } from '../../utils/monitor'
import { validateAndGetUserInfo } from '../../utils/validation_utils'
import { useNavigate } from 'react-router'

export default function UserSettings() {
    const getUserInfo = useGetUserInfo()

    // const updateUserAttributes = useUpdateUserAttributes()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [username, setUsername] = useState('...loading')
    const [phoneNumber, setPhoneNumber] = useState('...loading')
    const [email, setEmail] = useState('...loading')
    const navigate = useNavigate()

    let loadUserInfo = async () => {
        const userInfo = await validateAndGetUserInfo(getUserInfo, navigate)
        try {
            setUsername(userInfo?.username ?? '')
            setPhoneNumber(userInfo?.phoneNumber ?? '')
            setEmail(userInfo?.email ?? '')
        } catch (err) {
            toast(MessageConstants.UNABLE_TO_LOAD_USER_INFO, { type: 'error' })
            MonitorSingleton.sendException(err)
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        loadUserInfo()
    }, [])

    const userSettings = [
        {
            tag: 'Email:',
            value: email,
            onCommit: async (_newEmail: string | number) => {
                toast(MessageConstants.UNABLE_TO_UPDATE_USER_INFO, {
                    type: 'warning',
                })
                MonitorSingleton.sendMessage(
                    'User attempted to update their email settings'
                )
                // const updatedEmail = newEmail.toString()
                // try {
                //     updateUserAttributes({ email: updatedEmail })
                //     setEmail(updatedEmail)
                // } catch (err) {
                //     toast(MessageConstants.UNABLE_TO_UPDATE_USER_INFO, {
                //         type: 'error',
                //     })
                // }
            },
        },
        {
            tag: 'Phone Number:',
            value: phoneNumber,
            onCommit: async (_newPhoneNumber: string | number) => {
                toast(MessageConstants.UNABLE_TO_UPDATE_USER_INFO, {
                    type: 'warning',
                })
                MonitorSingleton.sendMessage(
                    'User attempted to update their phone number settings'
                )
                // try {
                //     const updatedPhoneNumber = newPhoneNumber.toString()
                //     await updateUserAttributes({
                //         phone_number: updatedPhoneNumber,
                //     })
                //     setPhoneNumber(updatedPhoneNumber)
                // } catch (err: any) {
                //     if (
                //         err.toString().includes('Invalid phone number format.')
                //     ) {
                //         toast(MessageConstants.BAD_INPUT_PHONE_NUMBER_FORMAT, {
                //             type: 'error',
                //         })
                //     } else {
                //         toast(MessageConstants.UNABLE_TO_UPDATE_USER_INFO, {
                //             type: 'error',
                //         })
                //     }
                // }
            },
        },
    ]

    if (isLoading) {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Title level={3}>User Settings</Title>
                </div>
                <Loading />
            </div>
        )
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>User Settings</Title>
            </div>
            <Space
                direction="vertical"
                size="large"
                style={{ display: 'flex' }}
            >
                <Row
                    key={'usernaem'}
                    justify="end"
                    align="middle"
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                >
                    <Col>
                        <Text>Username:</Text>
                    </Col>
                    <Col span={8}>
                        <Text>{username}</Text>
                    </Col>
                    <Col span={8} />
                </Row>
                {userSettings.map((element) => {
                    return (
                        <Row
                            key={element.tag}
                            justify="end"
                            align="middle"
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                            <Col>
                                <Text>{element.tag}</Text>
                            </Col>
                            <Col span={8}>
                                <TextOrInputFieldEdit
                                    stringValue={element.value}
                                    onCommitChange={element.onCommit}
                                />
                            </Col>
                            <Col span={8} />
                        </Row>
                    )
                })}
                {/* <Row
                    key={'change_password_button'}
                    justify="end"
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                >
                    <Col span={8}>
                        <Button>
                            <Link to={RouteConstants.CHANGE_PASSWORD_SETTINGS}>
                                Change Password
                            </Link>
                        </Button>
                    </Col>
                    <Col span={8} />
                </Row> */}
            </Space>
        </div>
    )
}
