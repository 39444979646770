import MessageConstants from '../../../message_constants'
import CacheConstants from '../../cache_constants'
import { UserInfo } from '../UserInfo'
import { SessionState } from '../SessionState'
import { UserStatus } from '../../UserStatus'
import { Cache } from 'aws-amplify'

import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js'
import RouteConstants from '../../../routes_constants'
import { MonitorSingleton } from '../../../utils/monitor'

let ID_TOKEN_EXPIRY_SAFETY_OFFSET_IN_SECONDS = 60

let auth0ClientSingleton: undefined | Auth0Client = undefined

async function getAuth0Client(): Promise<Auth0Client> {
    if (
        !process.env.REACT_APP_AUTH0_DOMAIN ||
        !process.env.REACT_APP_AUTH0_CLIENT_ID
    ) {
        throw new Error(MessageConstants.AUTH_NOT_CONFIGURED)
    }
    if (!auth0ClientSingleton) {
        auth0ClientSingleton = await createAuth0Client({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
            cacheLocation: 'localstorage',
            authorizationParams: {
                redirect_uri: window.location.origin,
            },
        })
        let _isAuthenticated = auth0ClientSingleton.isAuthenticated()
    }
    return auth0ClientSingleton
}

export async function getSessionToken(): Promise<string | undefined> {
    try {
        const auth0Client = await getAuth0Client()
        const idTokenClaims = await auth0Client.getIdTokenClaims()
        return idTokenClaims?.__raw
        // return await auth0Client.getTokenSilently()
    } catch (err) {
        MonitorSingleton.sendException(err)
    }
    return undefined
}

export function useGetUserInfo(): () => Promise<UserInfo | undefined> {
    return async (): Promise<UserInfo | undefined> => {
        try {
            if ((await getSessionState()) !== SessionState.LoggedIn) {
                return undefined
            }

            const auth0Client = await getAuth0Client()
            const user = await auth0Client.getUser()

            const userInfo = {
                id: user?.id ?? '',
                phoneNumber: user?.phone_number ?? '',
                email: user?.email ?? '',
                username: user?.username ?? '',
            }

            return userInfo
        } catch (err) {
            MonitorSingleton.sendException(err)
        }
        return undefined
    }
}

async function getSessionState(): Promise<SessionState> {
    try {
        const auth0Client = await getAuth0Client()
        const isAuthenticated = await auth0Client.isAuthenticated()
        if (!isAuthenticated) {
            return SessionState.LoggedOut
        }
        const idToken = await auth0Client.getIdTokenClaims()
        if (!idToken?.exp) {
            return SessionState.LoggedOut
        }

        const currentTimestampInSeconds =
            new Date().getTime() / 1000 +
            ID_TOKEN_EXPIRY_SAFETY_OFFSET_IN_SECONDS

        if (idToken.exp < currentTimestampInSeconds) {
            return SessionState.LoggedOut
        }
        return SessionState.LoggedIn
    } catch (err) {
        MonitorSingleton.sendException(err)
    }
    return SessionState.LoggedOut
}

export function useGetSessionState(): () => Promise<SessionState> {
    return getSessionState
}

export function useSignIn(): () => Promise<UserStatus> {
    return async () => {
        const auth0Client = await getAuth0Client()

        // Note: This will throw if the popup is closed without logging in
        await auth0Client.loginWithPopup({
            authorizationParams: {
                prompt: 'login',
            },
        })
        const _isAuthenticatedPostSignin = await auth0Client.isAuthenticated()
        if (!_isAuthenticatedPostSignin) {
            throw new Error(MessageConstants.GENERIC_LOGIN_FAILURE)
        }
        const _token = await auth0Client.getTokenSilently({})
        return UserStatus.Good
    }
}

export function useSignOut(): () => Promise<any> {
    return async () => {
        const auth0Client = await getAuth0Client()
        Cache.removeItem(CacheConstants.EXISTING_CONNECTIONS)

        auth0Client.logout({
            logoutParams: {
                returnTo: window.location.origin + RouteConstants.LOGIN,
            },
        })
    }
}
